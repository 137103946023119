import { Fragment, useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Label, Row } from "reactstrap";
import { Btn, H4 } from "../../../../AbstractElements";
import { useNavigate, useParams } from 'react-router-dom'
import * as Storage from "../../../../store/LocalStorage";
import { baseURL } from "../../../../Services/baseURL";
import endPoints from "../../../../Services/EndPoints";
import axios from "axios";
import Swal from "sweetalert2";


const EditCoupon = () => {
    const { id } = useParams()
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm()

    const navigate = useNavigate();
    const [formData, setFormData] = useState(null)

    const token = JSON.parse(Storage.get('token'))

    const fetchCouponById = async () => {
        try {
            const URL = `${baseURL}${endPoints.getCoupon}/${id}`
            const response = await axios.get(URL, {
                headers: {
                    'Authorization': `${token}`,
                }
            })
            if (response?.status == 200) {
                setFormData(response?.data?.data)
            }
            else {
                console.error('Failed to fetch data:', response.statusText);
            }
        }
        catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        if (id) {
            if (formData && setValue) {
                Object.keys(formData).forEach(key => {
                    if (key in formData) {
                        if (key === 'validFrom' || key === 'validTill') {
                            const formattedDate = formData[key].substring(0, 16);
                            setValue(key, formattedDate);
                        } else {
                            setValue(key, formData[key])
                        }
                    }
                });
            }
        }

    }, [formData, setValue])

    useEffect(() => {
        if (id)
            fetchCouponById();
    }, [])

    const onSubmit = async (data) => {
        const requestObj = {
            name: data.name,
            value: data.value,
            description: data.description,
            validFrom: data.validFrom,
            validTill: data.validTill,
        }

        if (id) {
            try {
                await axios.put(`${baseURL}${endPoints.updateCoupon}/${id}`,
                    requestObj,
                    {
                        headers: {
                            Authorization: `${token}`,
                        }
                    })
                Swal.fire({
                    icon: 'success',
                    title: 'Coupon has been updated successfully',
                    showConfirmButton: false,
                    timer: 1500
                }).then(() => {
                    navigate(-1)
                });
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.responseMessage,
                    showConfirmButton: true,
                });
            }
        }
        else {
            try {
                await axios.post(`${baseURL}${endPoints.addCoupon}`,
                    requestObj,
                    {
                        headers: {
                            Authorization: `${token}`,
                        }
                    })
                Swal.fire({
                    icon: 'success',
                    title: 'New coupon Added successfully',
                    showConfirmButton: false,
                    timer: 1500
                }).then(() => {
                    navigate(-1)
                });
            } catch (error) {
                console.error(error);
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.responseMessage,
                    showConfirmButton: true,
                });
            }
        }
    }


    return (
        <Fragment>
            <Container fluid={true}>
                <div className="page-title">
                    <Row>
                        <Col xs='6'>
                            <H4>{id ? "Edit Coupon" : "Add New Coupon"}</H4>
                        </Col>
                        <Col xs="6">
                            <div className="text-right">
                                <Btn attrBtn={{ className: "btn-secondary-gradien", type: "button" }} navigateBack={true}>Back</Btn>
                            </div >
                        </Col >
                    </Row >
                </div >
            </Container >
            <Container fluid={true}>
                <Row>
                    <Col sm='12'>
                        <Card>
                            <CardBody className="">
                                <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <Col sm='6'>
                                            <FormGroup>
                                                <Label>Coupon Name or Code<span className="text-danger">*</span></Label>
                                                <input type="text" className="form-control" name="name" placeholder="Enter Coupon Code" {...register('name', { required: true })} />
                                                <span style={{ color: 'red' }}>{errors.name && 'Coupon Code is required'}</span>
                                            </FormGroup>
                                        </Col>
                                        {/* </Row>
                                    <Row className="mt-3"> */}
                                        <Col sm='6'>
                                            <FormGroup>
                                                <Label>
                                                    Discount<span className="text-danger">*</span>
                                                </Label>
                                                <input
                                                    type="text"
                                                    name="value" // or amount
                                                    className="form-control"
                                                    placeholder="Enter coupon discount"
                                                    inputMode="numeric"
                                                    {...register('value', {
                                                        required: 'Discount is required',
                                                    })}
                                                />
                                                {errors.value && <span style={{ color: 'red' }}>{errors.value.message}</span>}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm='12'>
                                            <FormGroup>
                                                <Label>Description<span className="text-danger">*</span></Label>
                                                <textarea name="description" id="description" className="form-control" cols="10" rows="3" placeholder="Enter coupon description" {...register('description', { required: true })}></textarea>
                                                <span style={{ color: 'red' }}>{errors.description && 'description is required'}</span>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col sm="6">
                                            <FormGroup>
                                                <Label>Valid From<span className="text-danger">*</span></Label>
                                                <input
                                                    type={"datetime-local"}
                                                    name="validFrom"
                                                    className="form-control"
                                                    {...register('validFrom', {
                                                        valueAsDate: true,
                                                        required: 'Coupon valid from date is required',
                                                    })}
                                                />
                                                {errors.validFrom && <span style={{ color: 'red' }}>{errors.validFrom.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup>
                                                <Label>
                                                    Valid Till<span className="text-danger">*</span>
                                                </Label>
                                                <input
                                                    type="datetime-local"
                                                    name="validTill"
                                                    className="form-control"
                                                    {...register('validTill', {
                                                        valueAsDate: true,
                                                        required: 'Coupon valid till date is required',
                                                    })}
                                                />
                                                {errors.validTill && <span style={{ color: 'red' }}>{errors.validTill.message}</span>}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="text-end" style={{ marginRight: "30px", marginTop: "25px" }}>
                                                <Btn attrBtn={{ className: 'me-3 btn-secondary-gradien', type: "submit" }}>
                                                    {id ? "Update coupon" : "Add coupon"}
                                                </Btn>
                                                {/* <Btn attrBtn={{ className: "btn-secondary-gradien", type: "button", onClick: reset }}>
                                                    Reset
                                                </Btn> */}
                                                <Button className="btn-secondary-gradien" type="button" onClick={() => reset()}>
                                                    Reset
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment >
    );
}

export default EditCoupon;