import { Fragment, useEffect, useState } from 'react'
import { Button, Col, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Container, Form, Label, Input, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Btn, H1, P } from '../../../AbstractElements';
import { Edit, MoreVertical, PlusCircle, Trash2 } from 'react-feather';
import { baseURL } from '../../../Services/baseURL';
import endPoints from '../../../Services/EndPoints';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router';
// import { vehicleColumns } from '../../DataTableCols';
import Swal from 'sweetalert2';
import { useDataContext } from '../../../Context/hooks/useDataContext';
import CommonModal from '../../UiKits/Modals/common/modal';
import Select from 'react-select';
import { options } from '@fullcalendar/core/preact';
import { toast } from 'react-toastify';
import LogoutModal from '../reuseModal';


const VehiclesTable = () => {
    const { vehiclesList, setVehiclesList, isModalOpen, toggleModal } = useDataContext()
    const [driversList, setDriversList] = useState([])
    const [modal, setModal] = useState(false);
    const [expiryModal, setExpiryModal] = useState(true)
    const toggle = () => setModal(!modal);
    const [selectedDriver, setSelectedDriver] = useState(null)
    const [vehicleData, setVehicleData] = useState()
    const [existedDriver, setExistedDriver] = useState(null)
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);

    const navigate = useNavigate()
    const token = JSON.parse(localStorage.getItem('token'))

    const fetchVehicles = async () => {
        try {
            setLoading(true)
            const URL = `${baseURL}/admin/getAllVehicle?skip=0&limit=1000`
            const response = await axios.get(URL, {
                headers: {
                    'Authorization': `${token}`
                }
            })
            if (response?.status === 200 && response) {
                // console.log(response)
                setVehiclesList(response.data.data)
                setLoading(false)
            }
        }
        catch (error) {
            console.error(error)
            if (error.response.status === 403) {
                toggleModal(true)
            }
        }
    }

    useEffect(() => {
        fetchVehicles()
        // fetchServicesData()
    }, [currentPage, perPage])

    const fetchDriversList = async () => {
        try {
            const URl = `${baseURL}${endPoints.getAllDrivers}?skip=0&limit=1000`
            const response = await axios.get(URl, {
                headers: {
                    Authorization: `${token}`
                }
            })
            if (response?.status === 200) {
                setDriversList(response.data.data)
            }
            // console.log(driversList, "DL")

            // if (vehicleData) {
            //     const driverExist = driversList.map(driver => (
            //         driver._id === vehicleData.driverId? driver.name : null
            //     ))
            //     setSelectedDriver(driverExist)
            //     console.log(selectedDriver, driverExist, "main")
            // }
        }
        catch (error) {
            console.error(error)
            if (error.response.status === 403) {
                toggleModal(true)
            }
        }
    }


    useEffect(() => {
        if (vehicleData && driversList.length > 0) {
            const driverExist = driversList.find(driver => driver._id === vehicleData.driverId);

            setExistedDriver(driverExist ? {
                value: driverExist._id,
                label: driverExist.name
            } : null)
        }
    }, [vehicleData, driversList]);


    useEffect(() => {
        // if (modal) {
        fetchDriversList()
        // fetchSelectedDriver()
        // }
    }, [modal])

    const handleRemoveBtn = async () => {
        try {
            // console.log("remove")
            const URL = `${baseURL}/admin/removeAssignVehicle`
            // console.log("reqObj", URL, existedDriver, vehicleData)
            const data = {
                driverId: existedDriver ? existedDriver.value : null,
                vehicleId: vehicleData ? vehicleData._id : null
            }
            const response = await axios.post(URL, data, {
                headers: {
                    Authorization: `${token}`
                }
            })
            // console.log(response, "res")
            if (response?.status === 200) {
                fetchVehicles()
                toggle()
                // toast.success('Removed Successfully')
                setExistedDriver(null)
                // setSelectedDriver(null)
                setSelectedDriver(null)
                // console.log(existedDriver, selectedDriver)
                Swal.fire({
                    icon: 'success',
                    title: 'Driver Removed for this Vehicle',
                    showConfirmButton: false,
                    timer: 1500
                })
                // setVehiclesList(vehiclesList.filter(vehicle => vehicle._id!== vehicleData._id))
                // }
            }
        }
        catch (error) {
            console.error(error)
        }
    }

    const handleSaveBtn = async () => {
        if (selectedDriver === null) {
            toast.error("Please select a driver")
            return
        }

        const reqObj = {
            vehicleId: vehicleData._id,
            driverId: selectedDriver.value
        }


        // console.log(vehicleData)

        const confirmed = await Swal.fire({
            title: 'Are you sure?',
            text: `You want to assign ${vehicleData.brand} to ${selectedDriver.label}`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        });

        if (confirmed.isConfirmed) {
            try {
                const URL = `${baseURL}/admin/assignVehicle`
                const response = await axios.post(URL, reqObj, {
                    headers: {
                        Authorization: `${token}`
                    }
                })
                if (response?.status === 200) {
                    fetchVehicles()
                    setModal(!modal)
                }
                // console.log(response)
                //     if (response?.status === 200) {
                //         Swal.fire({
                //             icon: 'success',
                //             title: 'Vehicle Assigned Successfully',
                //             showConfirmButton: false,
                //             timer: 1500
                //         })
                //     }
                // }
                // catch (error) {
                //     console.error("Error deleting Vehicle data:", error)
                //     Swal.fire({
                //         icon: 'error',
                //         title: 'Oops...',
                //         text: "Something went wrong",
                //         showConfirmButton: false,
                //         timer: 1600
                //     });
                // }
                // }

                Swal.fire({
                    icon: 'success',
                    title: 'Done',
                    text: " You have successfully assigned driver to this vehicle",
                    showConfirmButton: true,
                    // timer: 1600/
                })
                // .then(() => {
                //     // toggle()
                // })

                setSelectedDriver(null)

            } catch (e) {
                console.error(e)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: "Something went wrong",
                    // `${e.response.data.responseMessage}`,
                    showConfirmButton: true,
                    // timer: 1600/
                });
            }
        }

    }

    const driverOptions = driversList
        .filter(driver => !driver.vehicle) // Filter out drivers who don't have a Vehicle field
        .map(driver => ({
            value: driver._id,
            label: driver.name ? driver.name : "No Name"
        }));

    // const driverOptions = driversList.map(driver => ({
    //     value: driver._id,
    //     label: driver.name ? driver.name : "No Name"
    // }))


    const handleAddBtn = () => {
        navigate("/vehicles/add")
    }

    const handleDelete = async (id) => {
        const confirmed = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this action!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });

        if (confirmed.isConfirmed) {
            try {
                await axios.delete(`${baseURL}/admin/deleteVehicle/${id}`, {
                    headers: {
                        Authorization: `${token}`,
                    }
                });
                Swal.fire({
                    icon: 'success',
                    title: 'Deleted!',
                    text: "Vehicle data deleted successfully",
                    showConfirmButton: false,
                    timer: 1600
                });
                setVehiclesList(vehiclesList.filter(vehicle => vehicle._id !== id))
            }
            catch (error) {
                console.error("Error deleting Vehicle data:", error)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: "Something went wrong",
                    showConfirmButton: false,
                    timer: 1600
                });
            }
        }
    }

    const handleEdit = async (id) => {
        navigate(`/vehicles/edit/${id}`)
    }

    const handlePageChange = (currentPage) => {
        // console.log(`Page ${currentPage}`)
        // console.log(`rows ${totalRows}`)
        setCurrentPage(currentPage);
    };

    const handlePerRowsChange = async (perPage, currentPage) => {
        // console.log(`Page ${perPage}, page ${currentPage}`)
        setPerPage(perPage);
        setCurrentPage(currentPage)
    };


    const vehicleColumns = [
        {
            name: 'Vehicle Name',
            selector: row => row.brand,
            cell: row => row.brand.charAt(0).toUpperCase() + row.brand.slice(1),
            sortable: true,
            center: true
        },
        {
            name: 'Model',
            selector: row => row.cabModel,
            cell: row => row.cabModel.charAt(0).toUpperCase() + row.cabModel.slice(1),
            sortable: true,
            center: true
        },
        {
            name: 'Reg Number',
            selector: row => row.regNumber,
            cell: row => row.regNumber.charAt(0).toUpperCase() + row.regNumber.charAt(1).toUpperCase() + row.regNumber.slice(2),
            sortable: true
        },
        {
            name: 'Service Type',
            // selector: row => row?.serviceTypedetails.type || 'Service not found',
            cell: row => {
                const service = row?.serviceTypedetails
                // servicesData.find(service => service._id === row.serviceType);
                return service ? service.name : 'Service not found';
            },
            sortable: true
        },
        {
            name: 'Driver Name',
            selector: row => row.driverId,
            // cell: row => {
            //     driversData
            // },
            cell: row => {
                const driver = row.driverDetails
                // driversList.find(driver => driver._id === row.driverId);
                return driver ? driver.name : 'Driver not found';
            },
            sortable: true
        },
        {
            name: 'AC Ride',
            selector: row => row.acRide,
            cell: row => {
                if (row.acRide === true) {
                    return "true";
                }
                else if (row.acRide === false) {
                    return "false";
                }
                else return "NA";
            },
            sortable: true
        },
        // {
        //     name: 'Action',
        //     // selector: 'action',
        //     sortable: true
        // }
    ]

    const actionColumn = [
        {
            name: 'Action',
            selector: row => row.action,
            cell: (row) => (
                <div className="d-flex">
                    <div className="cursor-pointer">
                        <UncontrolledDropdown className='action_dropdown'>
                            <DropdownToggle className='action_btn'
                            >
                                <MoreVertical color='#000' size={16} />
                            </DropdownToggle>
                            <DropdownMenu style={{ backgroundColor: "#7366ff" }}>
                                <DropdownItem className='d-flex align-items-center justify-between block px-4 py-2 text-sm text-black-700 hover:bg-gray-100 w-full'
                                    onClick={() => {
                                        toggle(modal)
                                        setVehicleData(row)
                                        // if(row.driverId ?)
                                        // setSelectedDriver(null)
                                        // setSelectedDriver(driversData.find(driver => driver._id === row.driverId))
                                    }
                                    }>
                                    Assign Driver
                                    {/* <Edit className="w-5 h-5 " /> */}
                                </DropdownItem>
                                <DropdownItem className='d-flex align-items-center justify-between block px-4 py-2 text-sm text-black-700 hover:bg-gray-100 w-full' onClick={() => handleEdit(row?._id)}>
                                    Edit
                                    <Edit className="w-5 h-5 " />
                                </DropdownItem>
                                <DropdownItem className='d-flex align-items-center justify-between delete_item block px-4 py-2 text-sm text-black-700 hover:bg-gray-100 w-full' onClick={() => handleDelete(row?._id)}>
                                    Delete
                                    <Trash2 className="w-5 h-5 " />
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </div>
            ),
            center: true,
            sortable: false,
            // width: 'auto',
            // maxWidth: 'none',
            // minWidth: '50px',
            // grow: 1,
            // conditionalCellStyles: [
            //     {
            //         when: cell => cell && cell.value,
            //         style: cell => ({
            //             width: `${cell.value.length * 10}px`
            //         })
            //     }
            // ]

        }
    ]

    const allColumns = vehicleColumns.concat(actionColumn);

    return (
        <Fragment>
            <Row xxl={12} className='pb-4'>
                <Row>
                    <Col md={12}>
                        <div className="flex justify-between">
                            <h1>Manage Vehicles</h1>
                            {
                                <Button className='btn btn-secondary-gradien d-flex flex-end align-items-center ms-3'
                                    onClick={handleAddBtn} >
                                    <PlusCircle className="w-6 h-6 mr-2" />
                                    Add New Vehicle
                                </Button>
                            }
                        </div>
                    </Col>
                </Row>
            </Row>
            {vehiclesList && vehiclesList.length !== 0 ? (
                <DataTable
                    data={vehiclesList || []}
                    columns={allColumns}
                    center={true}
                    // expandOnRowClicked
                    // expandableRows
                    // expandableRowsComponent={renderExpandableComponent}
                    // expandableRowsHideExpander
                    highlightOnHover
                    striped
                    // subHeader
                    responsive
                    dense
                    // subHeaderAlign="right"
                    // subHeaderWrap
                    // // subHeaderComponent={subHeaderContent}
                    pagination
                    paginationRowsPerPageOptions={[10, 15, 25, 30, 50]}
                    // paginationPerPage={10}
                    // paginationServer
                    progressPending={loading}
                    // paginationTotalRows={totalRows}
                    // paginationPerPage={5}
                    // // paginationPosition="top"
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                />
            ) : (
                <Fragment>
                    <Row style={{ minHeight: "250px" }}>
                        <Col md={12} className="flex justify-center">
                            <div className="text-center p-5">
                                <h1 className="text-muted">No data Available</h1>
                            </div>
                        </Col>
                    </Row>
                </Fragment>
            )}
            <Modal isOpen={modal} toggle={toggle} size="md" centered>
                <ModalHeader toggle={toggle}>
                    {existedDriver ? "Assigned Driver" : "Assign Driver"}
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <Form>
                            <Col sm="12">
                                <FormGroup>
                                    {/* <Label>Enroll Driver</Label> */}
                                    {/* <Input
                                        type="text"
                                        placeholder="Search and select a driver..."
                                        onChange={handleInputChange}
                                        className="form-control mb-2"
                                        value={searchTerm}
                                    />
                                    <div className='custom-dropdown'>

                                    </div> */}
                                    <Select
                                        placeholder="Search"
                                        value={existedDriver ? existedDriver : selectedDriver}
                                        options={existedDriver ? [existedDriver] : driverOptions}
                                        onChange={(selectedOption) => {
                                            setSelectedDriver(selectedOption)
                                        }}
                                        isSearchable={true}
                                    >
                                        {(selectedDriver || existedDriver !== null) && <p style={{ color: 'red' }}>Please select a driver</p>}
                                    </Select>

                                    {/* <Input type="select"
                                        onChange={(e) => {
                                            // const selectedDriverVal = (e.target.value);
                                            setSelectedDriver(e.target.value)
                                        }}
                                        className="form-control"
                                        value={selectedDriver}
                                    >
                                        <option value="">Select Driver</option>
                                        {
                                            driversData.map(driver => (
                                                <option key={driver._id} value={driver._id} > {driver.name}</option>
                                            ))
                                        }
                                    </Input> */}
                                </FormGroup>
                            </Col>
                        </Form>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    {existedDriver !== null ?
                        (<Button className='btn btn-secondary-gradien' type='button' onClick={handleRemoveBtn}>
                            Remove
                        </Button>) :
                        (<Button className='btn btn-secondary-gradien' onClick={handleSaveBtn}>
                            Save
                        </Button>)}
                    <Button color="danger" onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
            {isModalOpen && <LogoutModal />}
        </Fragment >

    );
}

export default VehiclesTable;