import React, { Fragment, useCallback, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { Btn, H4 } from '../../../AbstractElements';
import { dummytabledata, tableColumns } from '../../../Data/Table/Defaultdata';
import { Button, Col, Row } from 'reactstrap';
import { PlusCircle } from 'react-feather';
import * as Storage from '../../../store/LocalStorage'
import { useNavigate } from 'react-router';
import { baseURL } from '../../../Services/baseURL';
import axios from 'axios';
import { tripColumns } from '../../DataTableCols';
import { useDataContext } from '../../../Context/hooks/useDataContext';
import endPoints from '../../../Services/EndPoints';
import LogoutModal from '../reuseModal';

const TripTableComponent = () => {

    const { servicesData, setServicesData, isModalOpen, toggleModal } = useDataContext()
    const [allTripsList, setAllTripsList] = useState([]);

    // const [selectedRows, setSelectedRows] = useState([]);
    // const [toggleDelet, setToggleDelet] = useState(false);

    // const [alert, setAlert] = useState(false)

    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);

    const navigate = useNavigate()
    const token = JSON.parse(Storage.get("token"));

    const fetchAllTrips = async () => {
        try {
            setLoading(true)
            let params = {
                skip: (currentPage - 1) * perPage,
                limit: perPage,
            }
            const URL = `${baseURL}/admin/allTrip?skip=0&limit=1000`
            const response = await axios.get(URL, {
                headers: {
                    'Authorization': `${token}`
                },
                // params: params
            })
            // console.log(response, "response")
            if (response?.status === 200) {
                setAllTripsList(response?.data?.data)
                setTotalRows(response.data.totalRecord)
                setLoading(false)
            }
        } catch (error) {
            console.error(error)
            if (error.response.status === 403) {
                toggleModal(true)
            }
        }
    }

    const fetchAllServices = async () => {
        try {
            const response = await axios.get(`${baseURL}${endPoints.getServices}`, {
                headers: {
                    Authorization: `${token}`,
                },
            })
            if (response?.status === 200) {
                setServicesData(response.data.data)
                // setTotalRows(response.data.totalRecord)
                // setLoading(false)
            }
        }
        catch (error) {
            console.error(error, "Error fetching services")
        }
    }

    useEffect(() => {
        fetchAllTrips();
        fetchAllServices();
    }, [currentPage, perPage])


    // const handlePageChange = (currentPage) => {
    //     setCurrentPage(currentPage);
    // };

    // const handlePerRowsChange = async (perPage, currentPage) => {
    //     setPerPage(perPage);
    //     setCurrentPage(currentPage)
    // };


    // const handleRowSelected = useCallback(state => {
    //     setSelectedRows(state.selectedRows);
    // }, []);

    // const handleDelete = () => {
    //     if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.title)}?`)) {
    //         setToggleDelet(!toggleDelet);

    //         setData(data.filter((item) => selectedRows.filter((elem) => elem.id === item.id).length > 0 ? false : true));
    //         setSelectedRows('');
    //     }
    // };

    const serviceCol = [
        {
            name: 'Service Type',
            selector: row => row.serviceType,
            cell: row => {
                const service = servicesData.find(service => service._id === row.serviceType);
                return service ? service.name : 'Service not found';
            },
            sortable: true,
            left: true,
            grow: 0,
            wrap: true,
            width: '130px',
            sortable: true
        },
    ]

    const allColumns = serviceCol.concat(tripColumns)

    return (
        <Fragment>
            <Row xxl={12} className='pb-4'>
                <Row>
                    <Col md={12}>
                        <div className="flex justify-between">
                            <h2>Trips</h2>
                            {/* {
                                <Button className='btn btn-secondary-gradien d-flex flex-end align-items-center ms-3'
                                // onClick={handleAddBtn} 
                                >
                                    <PlusCircle className="w-6 h-6 mr-2" />
                                    Add New Vehicle
                                </Button>
                            } */}
                        </div>
                    </Col>
                </Row>
            </Row>
            {
                allTripsList?.length > 0 ? (
                    <DataTable
                        data={allTripsList}
                        columns={allColumns}
                        striped
                        // center={true}
                        center
                        highlightOnHover
                        responsive
                        dense
                        pagination
                        progressPending={loading}
                    // selectableRows
                    // onSelectedRowsChange={handleRowSelected}
                    // clearSelectedRows={toggleDelet}
                    />
                ) : (
                    <Fragment>
                        {/* <div className={`d-flex align-items-center justify-content-between bg-light-info p-2`}>
                            <H4 attrH4={{ className: 'text-muted m-0' }}>No Data Found..!</H4>
                        </div> */}
                        <Row style={{ minHeight: "250px" }}>
                            <Col md={12} className="flex justify-center">
                                <div className="text-center p-5">
                                    <h1 className="text-muted">No data Available</h1>
                                </div>
                            </Col>
                        </Row>
                    </Fragment>
                )
            }
            {isModalOpen && <LogoutModal />}
        </Fragment>
    )
}
export default TripTableComponent