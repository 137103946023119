import { Fragment } from "react";
import { Breadcrumb, Card, Col, Container, Row } from "reactstrap";
import DataTableComponent from "../../Tables/DataTable/DataTableComponent";

const BlacklistDriver = () => {
    return ( 
        <Fragment>
            <Breadcrumb mainTitle='Blacklist Driver'/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                          <DataTableComponent />  
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>

     );
}
 
export default BlacklistDriver;