import { Fragment } from "react"
import { Breadcrumbs } from "../../../AbstractElements"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import ServicesTable from "./DataTableComponent"



const ManageFleet = () => {
    return (
        <Fragment>
            {/* <Breadcrumbs mainTitle="Fleet serivces" /> */}
            <Container fluid={true} style={{ padding: '25px', minHeight: '150px' }}>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <ServicesTable />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default ManageFleet