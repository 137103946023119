
import { Fragment, useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
// import { H3 } from "../../../AbstractElements";
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
// import * as Yup from 'yup'
import { Breadcrumbs, Btn, H4 } from "../../../../AbstractElements";
// import PhoneInput from "react-phone-number-input"
// import ApiServices from "../../../../Services/ApiService";
import axios from "axios";
import { baseURL } from "../../../../Services/baseURL";
import endPoints from "../../../../Services/EndPoints";
import { Password } from "../../../../Constant";
import Swal from "sweetalert2";



const NewPartner = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        setValue
    } = useForm();

    const [formData, setFormData] = useState(null);
    const token = JSON.parse(localStorage.getItem("token"));

    const fetchPartnerById = async () => {
        try {
            const response = await axios.get(`${baseURL}${endPoints.getPartnerById}${id}`, {
                headers: {
                    Authorization: `${token}`
                }
            });
            // console.log(response)
            if (response?.status === 200 && response) {
                setFormData(response.data);
            }
        }
        catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        if (id)
            fetchPartnerById();
    }, []);

    useEffect(() => {
        if (id) {
            if (formData && setValue) {
                Object.keys(formData).forEach(key => {
                    if (key in formData) {
                        // if (key === 'validFrom' || key === 'validTill') {
                        //     const formattedDate = formData[key].substring(0, 18);
                        //     setValue(key, formattedDate);
                        // } else {
                        setValue(key, formData[key])
                        // }
                    }
                });
            }
        }

    }, [formData, setValue])

    const onSubmit = async (data) => {
        const requestObj = {
            name: data.partnerName,
            email: data.partnerEmail,
            // mobile: data.partnerMobile,
            password: data.partnerPassword
        }
        try {
            let URL = `${baseURL}${endPoints.addPartner}`
            const response = await axios.post(URL, requestObj, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token} `
                }
            })
            Swal.fire({
                icon: 'success',
                title: response.data.responseMessage,
                showConfirmButton: false,
                timer: 1500
            })
                // navigate("/partners")
                .then(() => {
                    // navigate(-1)
                    navigate("/partners")
                    // navigate(-1)
                })
        }
        catch (error) {
            console.error(error)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'something went wrong',
                showConfirmButton: false,
                timer: 1500
            });
        }
    }


    return (
        <Fragment>
            {/* <Breadcrumbs mainTitle="Partner" /> */}
            <Container fluid={true}>
                <div className="page-title">
                    <Row>
                        <Col xs='6'>
                            <H4>{id ? "Edit Partner" : "Add New Partner"}</H4>
                        </Col>
                        <Col xs="6">
                            <div className="text-right">
                                <Btn attrBtn={{ className: "btn-secondary-gradien", type: "button" }} navigateBack={true}>Back</Btn>
                            </div >
                        </Col >
                    </Row >
                </div >
            </Container >
            <Container fluid={true}>
                <Row>
                    <Col sm={12}>
                        <Card>
                            <CardBody>
                                <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <Row>
                                            <Col sm="8">
                                                <FormGroup>
                                                    <Label>Partner Name<span className="text-danger">*</span></Label>
                                                    <input className="form-control" type="text" name="partnerName" placeholder="Enter partner name *" {...register('partnerName', { required: true })} />
                                                    <span style={{ color: 'red' }}>{errors.partnerName && 'partner name is required'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>


                                        <Row>
                                            <Col sm="8">
                                                <FormGroup>
                                                    <Label>Email Id<span className="text-danger">*</span></Label>
                                                    <input className="form-control" type="text" name="partnerEmail" placeholder="Enter partner email *" {...register('partnerEmail', { required: true })} />
                                                    <span style={{ color: 'red' }}>{errors.partnerEmail && 'partner email is required'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="8">
                                                <FormGroup>
                                                    <Label>Password<span className="text-danger">*</span></Label>
                                                    <input className="form-control" type="text" name="partnerPassword" placeholder="Enter Password *" {...register('partnerPassword', { required: true })} />
                                                    <span style={{ color: 'red' }}>{errors.partnerPassword && 'partner password is required'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {/* <Row>
                                            <Col sm="8">
                                                <FormGroup>
                                                    <Label>Mobile<span className="text-danger">*</span></Label>
                                                    <input className="form-control" type="text" name="partnerMobile" placeholder="Enter mobile number *" inputMode="numeric" {...register('partnerMobile', { required: true })} />
                                                    <span style={{ color: 'red' }}>{errors.partnerMobile && 'partner number is required'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row> */}
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="text-end" style={{ marginRight: "30px", marginTop: "25px" }}>
                                                <Btn attrBtn={{ className: 'me-3 btn-secondary-gradien', type: "submit" }}>
                                                    {id ? "Update" : "Add"}
                                                </Btn>
                                                {/* <Btn attrBtn={{ className: "btn-secondary-gradien", type: "button", onClick: reset }}>
                                                    Reset
                                                </Btn> */}
                                                <Button className="btn-secondary-gradien" type="button" onClick={() => reset()}>
                                                    Reset
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row >
                                </Form >
                            </CardBody >
                        </Card >
                    </Col >
                </Row >
            </Container >
        </Fragment >
    );
}

export default NewPartner;
