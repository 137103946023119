import { createContext, useContext, useReducer } from "react";


const initialState = {
    servicesData: [],
    couponsData: [],
    driversData: [],
    loggedUserData: [],
    partnerData: [],
    taxData: [],
    vehiclesList: [],
    isModalOpen: false
}
// console.log(initialState, "Store state")
const dataReducer = (state, action) => {
    switch (action.type) {
        case "CHANGE_SERVICES_DATA":
            return {
                ...state,
                servicesData: action.payload
            }
        case "CHANGE_COUPONS_DATA":
            return {
                ...state,
                couponsData: action.payload
            }
        case "CHANGE_DRIVERS_DATA":
            return {
                ...state,
                driversData: action.payload
            }
        case "CHANGE_LOGGED_USER_DATA":
            return {
                ...state,
                loggedUserData: action.payload
            }
        case "CHANGE_PARTNER_DATA":
            return {
                ...state,
                partnerData: action.payload
            }
        case "CHANGE_TAX_DATA":
            return {
                ...state,
                taxData: action.payload
            }
        case "CHANGE_VEHICLE_DATA":
            return {
                ...state,
                vehiclesList: action.payload
            }
        case "TOGGLE_MODAL":
            return {
                ...state,
                isModalOpen: action.payload
                // !state.isModalOpen
            }
        default:
            return state;
    }
}
const DataContext = createContext();

export const useDataContext = () => useContext(DataContext);

export const DataContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(dataReducer, initialState);
    console.log(state, " state In Store")
    const setServicesData = (data) => dispatch({ type: 'CHANGE_SERVICES_DATA', payload: data })
    const setCouponsData = (data) => dispatch({ type: 'CHANGE_COUPONS_DATA', payload: data })
    const setDriversData = (data) => dispatch({ type: 'CHANGE_DRIVERS_DATA', payload: data })
    const setLoggedUserData = (data) => dispatch({ type: 'CHANGE_LOGGED_USER_DATA', payload: data })
    const setPartnerData = (data) => dispatch({ type: 'CHANGE_PARTNER_DATA', payload: data })
    const setTaxData = (data) => dispatch({ type: 'CHANGE_TAX_DATA', payload: data })
    const setVehiclesList = (data) => dispatch({ type: 'CHANGE_VEHICLE_DATA', payload: data })
    const toggleModal = (data) => dispatch({ type: 'TOGGLE_MODAL', payload: data });
    return (
        <DataContext.Provider value={{
            servicesData: state.servicesData,
            couponsData: state.couponsData,
            driversData: state.driversData,
            loggedUserData: state.loggedUserData,
            partnerData: state.partnerData,
            taxData: state.taxData,
            vehiclesList: state.vehiclesList,
            isModalOpen: state.isModalOpen,
            setServicesData,
            setCouponsData,
            setDriversData,
            setLoggedUserData,
            setPartnerData,
            setTaxData,
            setVehiclesList,
            toggleModal,
        }}>
            {children}
        </DataContext.Provider>
    )
}