import React from 'react';
import { useNavigate } from 'react-router';
import { Button } from 'reactstrap';


const Btn = (props) => {
    const navigate = useNavigate();

    const handleClick = () => {
        if (props.onClick) {
            props.onClick();
        }
        if (props.navigateBack) {
            navigate(-1);
        }
    }
    return <Button {...props.attrBtn} onClick={handleClick}>{props.children}</Button>;
};

export default Btn;