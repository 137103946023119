import { Fragment } from "react";
import { Container, Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import { Breadcrumbs } from "../../../AbstractElements";
import DriverTableComponent from "./driversListTable";



const ManageUser = () => {
    return ( 
        <Fragment>
            {/* <Breadcrumbs mainTitle='Manage Driver' parent='Driver' title='Manage'/> */}
            <Container fluid={true} style={{ padding: '25px', minHeight: '150px' }} >
                 <Row>
                    <Col sm="12">
                        <Card>
                            {/* <CardHeader>
                               Manage User
                            </CardHeader> */}
                            <CardBody style={{ minHeight: '600px' }}>
                                <DriverTableComponent/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
               
        </Fragment>
     );
}
 
export default ManageUser;