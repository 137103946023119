const endPoints = {

    //Admin Auth 
    adminLogin: "/admin/login",
    verifylogin: "/admin/verifyLogin",
    resendLogin: "/admin/resendOTP",
    logout: "/admin/logout",

    //Partner Auth
    partnerLogin: "/partner/login",
    verifyPartnerLogin: "/partner/verifyLogin",
    resendPartnerLogin: "/partner/resendOTP",
    logoutPartner: "/partner/logout",

    //Admin Profile
    getAdminProfile: "/admin/profile",
    updateAdminProfile: "/admin/profile",

    //partner profile
    getPartnerProfile: "/partner/profile",
    updatePartnerProfile: "/partner/profile",

    //Admin to Partner
    addPartner: "/admin/partner",
    deletePartner: "/admin/partner/",
    getPartners: "/admin/partner",
    getPartnerById: "/admin/partner/",

    //Admin to Driver
    getAllDrivers: "/admin/getAllDrivers",

    //Coupons
    addCoupon: "/admin/coupon",   //POST
    getCoupon: "/admin/coupon",  //GET
    updateCoupon: "/admin/coupon",  //PUT
    deleteCoupon: "/admin/coupon",  //DELETE
    getAllCoupons: "/admin/coupon",  //GET


    //Custom Notifications to User
    notifyDriver: "/admin/customizeNotification",
    notifyUser: "/admin/customizeNotification",  /// Check

    //services /*
    getServices: "/serviceType",
    addService: "/serviceType/",
    updateService: "/serviceType/",
    getServiceById: "/serviceType/",
    deleteService: "/serviceType/",

    //Taxes 
    addTax: "/tax",
    getTax: "/tax/",
    updateTax: "/tax/",
    deleteTax: "/tax/",
    getAllTaxes: "/tax",

    //Trip
    getTrips: "/trip/request",

    ////////////////////////////////

    // //trips

    // getTripById: "/get-trip-by-id",
    // addTrip: "/add-trip",
    // updateTrip: "/update-trip",
    // deleteTrip: "/delete-trip",

    //vehicles  
    getVehicles: "/get-vehicles",
    getVehicleById: "/get-vehicle-by-id",
    addVehicle: "/add-vehicle",
    updateVehicle: "/update-vehicle",
    deleteVehicle: "/delete-vehicle",

    //locations
    getLocations: "/get-locations",
    getLocationById: "/get-location-by-id",
    addLocation: "/add-location",
    updateLocation: "/update-location",
    deleteLocation: "/delete-location",


    //users
    getUsers: "/get-users",
    getUserById: "/get-user-by-id",
    addUser: "/add-user",
    updateUser: "/update-user",
    deleteUser: "/delete-user",


}

export default endPoints;