// dashbaord
// import Crypto from '../Components/Dashboard/Crypto';
import Default from '../Components/Dashboard/Default';
// import Ecommerce from '../Components/Dashboard/Ecommerce';
// import OnlineCourse from '../Components/Dashboard/OnlineCourse';
// import Social from '../Components/Dashboard/Social';

// // //widgets
// import Chart from '../Components/Widgets/Chart';
// import General from '../Components/Widgets/General';

// //page-layout
// import FooterLight from '../Components/PageLayout/FooterLight';

// import FooterDark from '../Components/PageLayout/FooterDark';
// // //ui-kits
// import Typography from '../Components/UiKits/Typography';
// import Avatars from '../Components/UiKits/Avtars';
// import HelperClasses from '../Components/UiKits/HelperClass';
// import Grid from '../Components/UiKits/Grid';
// import TagAndPills from '../Components/UiKits/TagAndPills';
// import Progressbars from '../Components/UiKits/Progress';
// import Modals from '../Components/UiKits/Modals';
// import Alert from '../Components/UiKits/Alert';
// import Popover from '../Components/UiKits/Popover';
// import Tooltips from '../Components/UiKits/Tooltip';
// import Spinners from '../Components/UiKits/Spinners';
// import Dropdowns from '../Components/UiKits/Dropdown';
// import Accordian from '../Components/UiKits/Accordian';
// import TabBootstrap from '../Components/UiKits/Tabs/BootstrapTab';
// import TabLine from '../Components/UiKits/Tabs/LineTab';
// import Navs from '../Components/UiKits/Navs';
// import Shadow from '../Components/UiKits/Shadows';
// import List from '../Components/UiKits/Lists';

// // //Bonus-ui
// import Scrollable from '../Components/Bonus-Ui/Scrollable';
// import TreeView from '../Components/Bonus-Ui/TreeView';
// import BootstrapNotify from '../Components/Bonus-Ui/BootstrapNotify';
// import RatingClass from '../Components/Bonus-Ui/Rating';
// import Dropzones from '../Components/Bonus-Ui/Dropzones';
// import Tours from '../Components/Bonus-Ui/Tour';
// import SweetAlerts from '../Components/Bonus-Ui/SweetAlert';
// import Carousels from '../Components/Bonus-Ui/Carousel';
// import Ribbons from '../Components/Bonus-Ui/Ribbons';
// import Paginations from '../Components/Bonus-Ui/Pagination';
// import Steps from '../Components/Bonus-Ui/Steps';
// import BreadcrumbClass from '../Components/Bonus-Ui/Breadcrumb';
// import RangeSlider from '../Components/Bonus-Ui/RangeSlider';
// import Imagecropper from '../Components/Bonus-Ui/ImageCropper';
// import Sticky from '../Components/Bonus-Ui/Sticky';
// import DragAndDrop from '../Components/Bonus-Ui/DragAndDrop';
// import UploadImage from '../Components/Bonus-Ui/ImageUpload';
// import BasicCards from '../Components/Bonus-Ui/Cards/BasicCards/index';
// import CreativeCards from '../Components/Bonus-Ui/Cards/CreativeCards';
// import TabCard from '../Components/Bonus-Ui/Cards/TabCard';
// import Timeline1 from '../Components/Bonus-Ui/Timeline/Timeline1';
// // // Icons
// import WeatherIcon from '../Components/Icons/WeatherIcon';
// import FeatherIcon from '../Components/Icons/FeatherIcon';
// import ThemifyIcon from '../Components/Icons/ThemifyIcon';
// import IcoIcon from '../Components/Icons/IcoIcon';
// import FontawesomeIcon from '../Components/Icons/FontawesomeIcon';
// import FlagIcon from '../Components/Icons/FlagIcon';

// // // Buttons
// import DefaultButton from '../Components/Buttons/Default';
// import FlatButton from '../Components/Buttons/FlatBtn/index';
// import EdgeButton from '../Components/Buttons/Edge/index';
// import RaisedButton from '../Components/Buttons/RaisedBtn/index';
// import GroupButton from '../Components/Buttons/GroupBtn/index';

// // //chart
// import ApexCharts from '../Components/Charts/ApexCharts';
// import ChartJs from '../Components/Charts/ChartsJs';
// import GoogleCharts from '../Components/Charts/GoogleCharts';

// // //forms
// import FormValidation from '../Components/Forms/FormControl/FormValidation';
// import BaseInput from '../Components/Forms/FormControl/BaseInput';
// import CheckboxandRadio from '../Components/Forms/FormControl/CheckRadio';
// import InputGroups from '../Components/Forms/FormControl/InputGroups';
// import MegaOption from '../Components/Forms/FormControl/MegaOption';
// import Datepicker from '../Components/Forms/FormWidget/DatePicker';
// import TypeaheadComp from '../Components/Forms/FormWidget/TypeaheadComponent/Typeahead';
// import FormDefault from '../Components/Forms/FormLayout/Default';
// import FormWizard from '../Components/Forms/FormLayout/FormWizard1/FormWizard1';
// import BasicTables from '../Components/Tables/Reactstrap/BasicTable';

// import DataTables from '../Components/Tables/DataTable';

// // //Application
// import Newproject from '../Components/Application/Project/Newproject';
// import Project from '../Components/Application/Project/Project';
// import Chat from '../Components/Application/Chat/ChatApp';
// import VideoChat from '../Components/Application/Chat/VideoChat';

// import Contact from '../Components/Application/Contact/ContactApp';
// import Task from '../Components/Application/Task';
// import TodoContain from '../Components/Application/Todo';

// // //Gallary
// import ImageGallery from '../Components/Gallery/ImageGallery';
// import MesonryDesc from '../Components/Gallery/MesonryDesc';
// import MasonryGallery from '../Components/Gallery/MesonryGallery';
// import ImageWithDesc from '../Components/Gallery/ImageWithDesc';
// import ImageHover from '../Components/Gallery/ImageHover';

// // //Blog
// import BlogDetail from '../Components/Blog/BlogDetail';
// import BlogPost from '../Components/Blog/BlogPost';
// import BlogSingle from '../Components/Blog/BlogSingle';

// // //Pages
// import SamplePage from '../Components/Pages/Sample';
// // // import SearchPage from '../Components/Application/SearchResult'

// // //Book mark
// import BookmarksContain from '../Components/Application/BookMarks';


// // //social app
// import SocialAppContain from '../Components/Application/SocialApp';

// //calender
// import CalenderContain from '../Components/Application/Calender/Index';

// // //Email
// import MailInboxContain from '../Components/Application/Email/MailInbox';

// // //File Manager
// import FileManagerContain from '../Components/Application/FileManager';
// import SearchResultContain from '../Components/Application/SearchResult';

// // //E-commerce
// import Products from '../Components/Application/Ecommerce/Products';
// import ProductCart from '../Components/Application/Ecommerce/ProductCart';
// import WishList from '../Components/Application/Ecommerce/Wishlist';
// import CheckOut from '../Components/Application/Ecommerce/CheckOut';
// import Invoice from '../Components/Application/Ecommerce/Invoice';
// import OrderHistory from '../Components/Application/Ecommerce/OrderHistory';
// import ProductPage from '../Components/Application/Ecommerce/ProductPage';
// import PricingMemberShip from '../Components/Application/Ecommerce/PricingMemberShip';
// import PaymentDetails from '../Components/Application/Ecommerce/PaymentDetails';
// import ProductListContain from '../Components/Application/Ecommerce/ProductList';
// import DraggableContain from '../Components/Application/Calender/DraggableCalender';

// // //Job Search
// import JobCards from '../Components/JobSearch/JobCardView';
// import ListView from '../Components/JobSearch/ListView';
// import JobDetails from '../Components/JobSearch/JobDetail';
// import Apply from '../Components/JobSearch/Apply';

// // //Learning
// import LearningList from '../Components/Learning/LearningList';
// import DetailCourse from '../Components/Learning/DetailCourse';

// // //Map
// import GoogleMaps from '../Components/Map/GoogleMap';
// import PigeonMap from '../Components/Map/Pigeon Map';

// // //Editor
// import CkEditorContain from '../Components/Editor/CkEditor';
// import MdeEDitorContain from '../Components/Editor/MDEEditor/MDEEditor';
// import ACEEditorContain from '../Components/Editor/AceCodeEditor/ACECodeEditor';

// import KnowledgeBaseContain from '../Components/Knowledgebase/KnowledgeBase';
// import KnowledgeCategoryContain from '../Components/Knowledgebase/KnowledgeCategory';
// import KnowledgeDetailContain from '../Components/Knowledgebase/KnowledgeDetails';
// import SupportTickitContain from '../Components/SupportTicket';
// import DateTimeContain from '../Components/Forms/FormWidget/DateandTime';
// import FormDateRangepickerContain from '../Components/Forms/FormWidget/FormDateRangePicker';
// import FormSelectContain from '../Components/Forms/FormWidget/FormSelect2';
// import FormSwitchContain from '../Components/Forms/FormWidget/FormSwitch';
// import FormTouchspinContain from '../Components/Forms/FormWidget/FormTouchspin';
// import FormClipboardContain from '../Components/Forms/FormWidget/FormClipboard';
// import FAQContent from '../Components/FAQ';

// // //User app
// import UsersCardssContain from '../Components/Application/Users/UsersCards';
// import UsersEditContain from '../Components/Application/Users/UsersEdit';
// import UsersProfileContain from '../Components/Application/Users/UsersProfile';



import ManageUser from '../Components/Drivers/Manage';
import BlacklistDriver from '../Components/Drivers/Blacklist';
import DriverOnboarding from '../Components/Drivers/Onboard';
import Trips from '../Components/Main/Trips';
import Payments from '../Components/Main/Payments';
import ManageFleet from '../Components/Main/Fleet';

// import CreateService from '../Components/Main/Fleet/bin';
import EditService from '../Components/Main/Fleet/editService';
import ManageCoupons from '../Components/Main/Coupons';
import EditCoupons from '../Components/Main/Coupons/editCoupon';
import ManagePartners from '../Components/Main/Partners';
// import AddPartner from '../Components/Main/Partners/newParnter';
import NewPartner from '../Components/Main/Partners/Newpartner';
import ManageTaxes from '../Components/Main/Taxes';
import ManageProfile from '../Components/Main/Profile';
import NewTax from '../Components/Main/Taxes/Newtax';

import Dropzones from '../Components/Bonus-Ui/Dropzones';
import ManageVehicles from '../Components/Main/Vehicles';
import EditVehicles from '../Components/Main/Vehicles/AddVehicles';
// import createDriver from '../Components/Drivers/Manage/AddDriver';
import CreateDriver from '../Components/Drivers/Manage/AddDriver';
// import Dropzone from 'react-dropzone-uploader';
export const routes = [
  {
    path: `/dashboard`,
    Component: <Default />,
  },
  {
    path: `/drivers/manage`,
    Component: <ManageUser />
  },
  {
    path: `/drivers/blacklist`,
    Component: <BlacklistDriver />
  },
  {
    path: `/drivers/onboarding`,
    Component: <DriverOnboarding />
  },
  {
    path: `/trips`,
    Component: <Trips />
  },
  {
    path: `/payments`,
    Component: <Payments />
  },
  {
    path: `/fleet`,
    Component: <ManageFleet />
  },
  // {
  //   path: `/fleet/addnewservice`,
  //   Component: <CreateService />
  // },
  {
    path: `/fleet/addnewservice`,
    Component: <EditService />
  },
  {
    path: `fleet/editService/:id`,
    Component: <EditService />
  },
  {
    path: `/coupons`,
    Component: <ManageCoupons />
  },
  {
    path: `/partners`,
    Component: <ManagePartners />
  },
  {
    path: `/addPartner`,
    Component: <NewPartner />
  },
  {
    path: `/partner/edit/:id`,
    Component: <NewPartner />
  },
  {
    path: `/coupons/edit/:id`,
    Component: <EditCoupons />
  },
  {
    path: '/coupons/add',
    Component: <EditCoupons />
  },
  {
    path: '/allTaxes',
    Component: <ManageTaxes />
  },
  {
    path: '/addTax',
    Component: <NewTax />
  },
  {
    path: '/taxes/editTax/:id',
    Component: <NewTax />
  },
  {
    path: '/vehicles',
    Component: <ManageVehicles />
  },
  {
    path: '/vehicles/add',
    Component: <EditVehicles />
  },
  {
    path: '/vehicles/edit/:id',
    Component: <EditVehicles />
  },
  {
    path: '/myProfile',
    Component: <ManageProfile />
  },
  {
    path: '/dropZone',
    Component: <Dropzones />
  },
  {
    path: '/driver/create',
    Component: <CreateDriver />
  },
  {
    path: '/driver/edit/:id',
    Component: <CreateDriver />
  }
]