import React  from 'react';
import { Media } from 'reactstrap';
import { Image } from '../../AbstractElements';
import { Edit2, Eye, Trash2 } from 'react-feather';
import user1 from '../../assets/images/user/1.jpg';
import user2 from '../../assets/images/user/2.png';
import user3 from '../../assets/images/user/3.jpg';
import user4 from '../../assets/images/user/3.png';
import user5 from '../../assets/images/user/4.jpg';
import user6 from '../../assets/images/user/5.jpg';
import user7 from '../../assets/images/user/6.jpg';
import user8 from '../../assets/images/user/7.jpg';
import user9 from '../../assets/images/user/8.jpg';
import user10 from '../../assets/images/user/9.jpg';
import user11 from '../../assets/images/user/10.jpg';
import user12 from '../../assets/images/user/11.png';
import user13 from '../../assets/images/user/12.png';

// Define state variables to manage active dropdown and any other state needed
// const [activeDropdown, setActiveDropdown] = useState(null);

// // Function to toggle dropdown visibility
// const toggleDropdown = (id) => {
//     setActiveDropdown(activeDropdown === id ? null : id);
// };

// // Function to handle edit action
// const handleEdit = (row) => {
//     // Add logic to handle edit action
//     console.log("Editing row:", row);
// };

// // Function to handle delete action
// const handleDelete = (row) => {
//     // Add logic to handle delete action
//     console.log("Deleting row:", row);
// };

// Define state variable to manage active dropdown
// let activeDropdown = null;

// // Function to toggle dropdown visibility
// const toggleDropdown = (id) => {
//     activeDropdown = activeDropdown === id ? null : id;
//     updateDropdownVisibility();
// };

// // Function to handle edit action
// const handleEdit = (row) => {
//     // Add logic to handle edit action
//     console.log("Editing row:", row);
// };

// // Function to handle delete action
// const handleDelete = (row) => {
//     // Add logic to handle delete action
//     console.log("Deleting row:", row);
// };

// // Function to update dropdown visibility
// const updateDropdownVisibility = () => {
//     const dropdowns = document.querySelectorAll('.dropdown-menu');
//     dropdowns.forEach(dropdown => {
//         if (dropdown.id === activeDropdown) {
//             dropdown.classList.add('block');
//             dropdown.classList.remove('hidden');
//         } else {
//             dropdown.classList.remove('block');
//             dropdown.classList.add('hidden');
//         }
//     });
// };

// Define state variable to manage active dropdown
let activeDropdown = null;

// Function to toggle dropdown visibility
const toggleDropdown = (id) => {
    activeDropdown = activeDropdown === id ? null : id;
    updateDropdownVisibility();
};

// Function to handle edit action
const handleEdit = (row) => {
    // Add logic to handle edit action
    console.log("Editing row:", row);
};

// Function to handle delete action
const handleDelete = (row) => {
    // Add logic to handle delete action
    console.log("Deleting row:", row);
};

// Function to update dropdown visibility
const updateDropdownVisibility = () => {
    const dropdowns = document.querySelectorAll('.dropdown-menu');
    dropdowns.forEach(dropdown => {
        const dropdownId = dropdown.getAttribute('data-dropdown-id');
        if (dropdownId === activeDropdown) {
            dropdown.style.display = 'block';
        } else {
            dropdown.style.display = 'none';
        }
    });
};




export const dummytabledata = [
    {
        id: 1,
        name: <Media className='d-flex'><Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${user1}`, alt: 'Generic placeholder image' }} />
            <Media body className="align-self-center">
                <div>Airi Satou</div>
            </Media>
        </Media>,
        date: '2023/04/27',
        invoice: '#PX0101',
        designation: 'System Architect',
        credit: <span className="f-w-700 font-success"><i className="icofont icofont-arrow-up"></i> 4.3%</span>,
        company: 'Hewlett packard',
        priority: <span className='badge badge-light-primary'>High</span>,
        budget: '$3142.00',

    },
    {
        id: 2,
        name: <Media className='d-flex'><Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${user2}`, alt: 'Generic placeholder image' }} />
            <Media body className="align-self-center">
                <div>Thomas Taylor</div>
            </Media>
        </Media>,
        date: '2023/04/22',
        invoice: '#RF304f',
        designation: 'Maintenace service',
        credit: <span className="f-w-700 font-success"><i className="icofont icofont-arrow-up"></i> 5.6%</span>,
        company: 'Apple Inc.',
        priority: <span className='badge badge-light-danger'>Urgent</span>,
        budget: '$1234.00',

    },
    {
        id: 3,
        name: <Media className='d-flex'><Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${user3}`, alt: 'Generic placeholder image' }} />
            <Media body className="align-self-center">
                <div>Michael Morris</div>
            </Media>
        </Media>,
        date: '2023/05/21',
        invoice: '#DNJ480',
        designation: 'Junior Technical Author	',
        credit: <span className="f-w-700 font-danger"><i className="icofont icofont-arrow-down"></i> 2.4%</span>,
        company: 'Edinburgh',
        priority: <span className='badge badge-light-success'>Low</span>,
        budget: '$2345.00',

    },
    {
        id: 4,
        name: <Media className='d-flex'><Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${user4}`, alt: 'Generic placeholder image' }} />
            <Media body className="align-self-center">
                <div>Tiger Nixon</div>
            </Media>
        </Media>,
        date: '2023/03/09',
        invoice: '#G189d0',
        designation: 'Senior Javascript Developer',
        credit: <span className="f-w-700 font-danger"><i className="icofont icofont-arrow-down"></i> 2.2%</span>,
        company: 'Microsoft',
        priority: <span className='badge badge-light-info'>Medium</span>,
        budget: '$7689.00',

    },
    {
        id: 5,
        name: <Media className='d-flex'><Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${user5}`, alt: 'Generic placeholder image' }} />
            <Media body className="align-self-center">
                <div>Garrett Winters</div>
            </Media>
        </Media>,
        date: '2023/04/10',
        invoice: '#31D8FFS',
        designation: 'Accountant',
        credit: <span className="f-w-700 font-success"><i className="icofont icofont-arrow-up"></i> 5.8%</span>,
        company: 'Tata Co.',
        priority: <span className='badge badge-light-primary'>High</span>,
        budget: '$2145.00',

    },
    {
        id: 6,
        name: <Media className='d-flex'><Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${user6}`, alt: 'Generic placeholder image' }} />
            <Media body className="align-self-center">
                <div>Carolyn Jones</div>
            </Media>
        </Media>,
        date: '2023/06/12',
        invoice: '#G189D4',
        designation: 'General service',
        credit: <span className="f-w-700 font-success"><i className="icofont icofont-arrow-up"></i> 6.4%</span>,
        company: 'Google Inc.',
        priority: <span className='badge badge-light-danger'>Urgent</span>,
        budget: '$2578.00',

    },
    {
        id: 7,
        name: <Media className='d-flex'><Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${user7}`, alt: 'Generic placeholder image' }} />
            <Media body className="align-self-center">
                <div>Glen Matney</div>
            </Media>
        </Media>,
        date: '2023/04/25',
        invoice: '#PX2101',
        designation: 'System Architect',
        credit: <span className="f-w-700 font-danger"><i className="icofont icofont-arrow-down"></i> 0.3%</span>,
        company: 'Mindtree Ltd.',
        priority: <span className='badge badge-light-success'>Low</span>,
        budget: '$6538.00',

    },
    {
        id: 8,
        name: <Media className='d-flex'><Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${user8}`, alt: 'Generic placeholder image' }} />
            <Media body className="align-self-center">
                <div>Ashton Cox</div>
            </Media>
        </Media>,
        date: '2023/06/09',
        invoice: '#px0101',
        designation: 'System Architect',
        credit: <span className="f-w-700 font-success"><i className="icofont icofont-arrow-up"></i> 7.3%</span>,
        company: 'Wipro Ltd.',
        priority: <span className='badge badge-light-danger'>Urgent</span>,
        budget: '$2121.00',

    },
    {
        id: 9,
        name: <Media className='d-flex'><Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${user9}`, alt: 'Generic placeholder image' }} />
            <Media body className="align-self-center">
                <div>Tiger Nixon</div>
            </Media>
        </Media>,
        date: '2023/01/11',
        invoice: '#G5384H',
        designation: 'General service',
        credit: <span className="f-w-700 font-success"><i className="icofont icofont-arrow-up"></i> 6.3%</span>,
        company: 'Edinburgh',
        priority: <span className='badge badge-light-primary'>High</span>,
        budget: '$9546.00',

    },
    {
        id: 10,
        name: <Media className='d-flex'><Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${user10}`, alt: 'Generic placeholder image' }} />
            <Media body className="align-self-center">
                <div>Brielle Williamson</div>
            </Media>
        </Media>,
        date: '2023/04/02',
        invoice: '#E5384H',
        designation: 'System Architect',
        credit: <span className="f-w-700 font-danger"><i className="icofont icofont-arrow-down"></i> 3.3%</span>,
        company: 'Mphasis Ltd',
        priority: <span className='badge badge-light-info'>Medium</span>,
        budget: '$4587.00',

    },
    {
        id: 11,
        name: <Media className='d-flex'><Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${user11}`, alt: 'Generic placeholder image' }} />
            <Media body className="align-self-center">
                <div>Charles Kubik</div>
            </Media>
        </Media>,
        date: '2023/05/01',
        invoice: '#JK384H',
        designation: 'System Architect',
        credit: <span className="f-w-700 font-danger"><i className="icofont icofont-arrow-down"></i> 2.3%</span>,
        company: 'Infosys Ltd.',
        priority: <span className='badge badge-light-success'>Low</span>,
        budget: '$3140.00',

    },
    {
        id: 12,
        name: <Media className='d-flex'><Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${user12}`, alt: 'Generic placeholder image' }} />
            <Media body className="align-self-center">
                <div>Brielle Williamson</div>
            </Media>
        </Media>,
        date: '2023/07/04',
        invoice: '#HY5384H',
        designation: 'General service',
        credit: <span className="f-w-700 font-danger"><i className="icofont icofont-arrow-down"></i> 1.3%</span>,
        company: 'Edinburgh',
        priority: <span className='badge badge-light-success'>Low</span>,
        budget: '$3014.00',

    },
    {
        id: 13,
        name: <Media className='d-flex'><Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${user13}`, alt: 'Generic placeholder image' }} />
            <Media body className="align-self-center">
                <div>Kevin Dawson</div>
            </Media>
        </Media>,
        date: '2022/04/06',
        invoice: '#KH384H',
        designation: 'System Architect',
        credit: <span className="f-w-700 font-success"><i className="icofont icofont-arrow-up"></i> 5.3%</span>,
        company: 'Infosys.',
        priority: <span className='badge badge-light-danger'>Urgent</span>,
        budget: '$2014.00',

    }
];

export const tableColumns = [
    {
        name: 'Name',
        selector: row => row['name'],
        sortable: true,
        center: false,
    },
    {
        name: 'Vehcile',
        selector: row => `${row.date}`,
        sortable: true,
        center: true,
    },
    {
        name: 'Vehcile No',
        selector: row => `${row.invoice}`,
        sortable: true,
        center: true,
    },
    {
        name: 'No of Rides',
        selector: row => `${row.designation}`,
        sortable: true,
        center: true,
    },
    {
        name: 'Rating',
        selector: row => row['credit'],
        sortable: true,
        center: true,
    },
    {
        name: 'Area',
        selector: row => `${row.company}`,
        sortable: true,
        center: true,
    },
    {
        name: 'Status',
        selector: row => row['priority'],
        sortable: true,
        center: true,
        cell: (row) => (
            <div className='d-flex justify-content-end align-items-center'>
                <p style={{ fontWeight: '500' }} className={`text-${row.status === "inactive" ? "[#ff0000]" : "[#008800]"}`}>{row.status === "active" ? "Active" : "Inactive"}</p>
            </div>
        )
    },
    {
        name: 'Budget',
        selector: row => row['budget'],
        sortable: true,
        center: true,
    },
    // {
    //     name: 'Actions',
    //     cell: (row) => (
    //         <div className='d-flex justify-content-end align-items-center'>
    //             <div className='dropdown'>
    //                 <button type='button' className='btn btn-secondary dropdown-toggle' data-bs-toggle='dropdown' aria-expanded='false'>
    //                     <i className='icofont icofont-ui-settings'></i>
    //                 </button>
    //                 <ul className='dropdown-menu dropdown-menu-end'>
    //                     <li><a className='dropdown-item' href='#'>Edit</a></li>
    //                     <li><a className='dropdown-item' href='#'>Delete</a></li>
    //                 </ul>
    //             </div>
    //         </div>
    //         ),
    //     sortable: false,
    // }

    // {
    //     name: 'Actions',
    //     cell: (row) => (
    //         <div className='d-flex justify-content-end align-items-center'>
    //             <div className='dropdown'>
    //                 <button type='button' className='btn btn-secondary dropdown-toggle' data-bs-toggle='dropdown' aria-expanded='false'>
    //                     <i className='icofont icofont-ui-settings'></i>
    //                 </button>
    //                 <ul className='dropdown-menu dropdown-menu-end'>
    //                     <li><a className='dropdown-item' href='#' onClick={() => handleEdit(row)}>Edit</a></li>
    //                     <li><a className='dropdown-item' href='#' onClick={() => handleDelete(row)}>Delete</a></li>
    //                 </ul>
    //             </div>
    //         </div>
    //     ),
    //     sortable: false,
    // }
    // {
    //     name: 'Actions',
    //     cell: (row) => (
    //         <div className='relative'>
    //             <button className='flex items-center justify-center w-8 h-8 bg-gray-200 rounded-full' onClick={() => toggleDropdown(row.id)}>
    //                 <svg className='w-4 h-4 text-gray-600' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
    //                     <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M19 9l-7 7-7-7' />
    //                 </svg>
    //             </button>
    //             <div className={'absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10 ' + (row.id === activeDropdown ? 'block' : 'hidden')}>
    //                 <a href='#' className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100' onClick={() => handleEdit(row)}>Edit</a>
    //                 <a href='#' className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100' onClick={() => handleDelete(row)}>Delete</a>
    //             </div>
    //         </div>
    //     ),
    //     sortable: false,
    // }

//    {
//     name: 'Actions',
//     cell: (row) => (
//         <div className='relative'>
//             <button className='flex items-center justify-center w-8 h-8 bg-gray-200 rounded-full hover:bg-gray-300' onMouseEnter={() => toggleDropdown(row.id)} onMouseLeave={() => toggleDropdown(null)}>
//                 <svg className='w-4 h-4 text-gray-600' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
//                     <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M19 9l-7 7-7-7' />
//                 </svg>
//             </button>
//             <div className={'absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10 ' + (row.id === activeDropdown ? 'block' : 'hidden')} onMouseEnter={() => toggleDropdown(row.id)} onMouseLeave={() => toggleDropdown(null)}>
//                 <a href='#' className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100' onClick={() => handleEdit(row)}>Edit</a>
//                 <a href='#' className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100' onClick={() => handleDelete(row)}>Delete</a>
//             </div>
//         </div>
//     ),
//     sortable: false,
// }

// {
//     name: 'Actions',
//     cell: (row) => (
//         <div className='d-flex justify-content-center align-items-center' style={{ marginLeft: '20px' }}>

//             <span className='rounded-2' style={{ cursor: 'pointer', marginRight: '10px', border: '1px solid #008000', padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//                 <Edit2 color='#008000' size={16} />
//             </span>
//             <span className='rounded-2' style={{ cursor: 'pointer', border: '1px solid #ff0000', padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//                 <Trash2 color='#ff0000' size={16} />
//             </span>
//         </div>
//     ),
//     sortable: false,
//     center: true,
// }
{
    name: 'Actions',
    cell: (row) => (
        <div className='d-flex justify-content-center align-items-center' style={{ marginLeft: '20px' }}>

            <span className='rounded-2' style={{ cursor: 'pointer', marginRight: '10px', border: '1px solid #008000', padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Edit2 color='#008000' size={16} />
            </span>
            <span className='rounded-2' style={{ cursor: 'pointer', border: '1px solid #ff0000', padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Trash2 color='#ff0000' size={16} />
            </span>
        </div>
    ),
    sortable: false,
    center: true,
}
    
    
];