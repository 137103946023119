import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { useNavigate } from 'react-router';
import { P } from '../../../AbstractElements';
import { useAuthContext } from '../../../Context/Auth';
import { useDataContext } from '../../../Context/hooks/useDataContext';

const LogoutModal = (props) => {
    const { logout } = useAuthContext()
    const navigate = useNavigate()
    const { isModalOpen, toggleModal } = useDataContext();

    const handleModal = () => {
        // logout()
        // localStorage.clear()
        navigate("/login")
        toggleModal(false)
    }

    return (
        <Modal isOpen={isModalOpen} size={"md"} centered>
            <ModalHeader className="d-flex justify-content-center text-center" toggle={props.toggler}>
                {/* "propstitle" */}
                <p>
                    Your session has been expired
                </p>
                <p>
                    Please Login again
                </p>
                {/* Your session has been expired, Please Login again */}

            </ModalHeader>
            {/* <ModalBody className={props.bodyClass}>
                <P>
                    Your session has been expired, Please Login again
                </P>
            </ModalBody> */}
            <ModalFooter className="d-flex justify-content-center">
                <Button className='btn btn-secondary-gradien' type="button" onClick={handleModal}>Login</Button>
            </ModalFooter>
        </Modal>
    );
};

export default LogoutModal;