import React, { Fragment, useState, useEffect, useContext } from 'react';
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Btn, H4, P } from '../AbstractElements';
import axios from 'axios';
import {
  EmailAddress,
  ForgotPassword,
  Password,
  RememberPassword,
  SignIn,
} from '../Constant';

import { Link, useNavigate } from 'react-router-dom';
import man from '../assets/images/dashboard/profile.png';

import CustomizerContext from '../_helper/Customizer';
import OtherWay from './OtherWay';
import { ToastContainer, toast } from 'react-toastify';
import { baseURL } from '../Services/baseURL';
import endPoints from '../Services/EndPoints';
import ApiServices from '../Services/ApiService';
import { useDataContext } from '../Context/hooks/useDataContext';
import { useAuthContext } from '../Context/Auth';


const Signin = ({ }) => {
  // const { loggedUserData, setLoggedUserData } = 
  const { updateUserContext } = useAuthContext()
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [togglePassword, setTogglePassword] = useState(false);
  const history = useNavigate();

  // // const [value, setValue] = useState(localStorage.getItem('profileURL' || man)); // Profile IMg
  // const [name, setName] = useState();
  const [loggedUser, setLoggedUser] = useState("");

  // useEffect(() => {
  //   // localStorage.setItem('profileURL', man);
  //   localStorage.setItem('profileData', );
  // }, [name]);

  const loginAuth = async (e) => {
    e.preventDefault();
    if (!loggedUser) {
      toast.error("Please select your login type");
      return;
    }
    try {
      const loginData = {
        password: password,
        email: email,
        fcmToken: "dlXfo-guFk5etAzOPMbsBT:APA91bG_2fADOF-_q3VTJySJEacIA_9o0QS-x3br5PYZYihS3bt26bC6RobpVjG0R5E_bfoo7P2kojKr7P46EJ29ligwwHybkziIsfEyRYDnrx3s7sUzqsdTww9YeHtUSyFhLQkkSHij"
      }

      const userEndPoint = loggedUser === "Admin" ? endPoints.adminLogin : endPoints.partnerLogin

      const userRes = await axios.post(`${baseURL}${userEndPoint}`, loginData)

      if (userRes.status === 200) {
        // toast.success("Login Successfull")
        toast.success(`${userRes.data.responseMessage}`)
        const resData = userRes.data.data
        updateUserContext(resData)
        // console.log(userRes, " data")


        const token = resData.accessToken
        localStorage.setItem("token", JSON.stringify(token))
        localStorage.setItem("login", JSON.stringify(true));
        localStorage.setItem("role_name", JSON.stringify(resData.userType))
        localStorage.setItem("isActive", JSON.stringify(resData.isActive))
        localStorage.setItem("profileData", JSON.stringify(resData))

        history(`/dashboard`);
        toast.success(`${userRes.data.responseMessage}`)
      }
      //  else {
      //   toast.error("Invalid Credentials")
      // }
    } catch (error) {
      toast.error("Invalid Credentials")
      // toast.error("You enter wrong password or username!..");
      // toast.error("You enter wrong password or username!..");
      // toast.error(`${userRes.data.responseMessage}`);
      console.error(error)
    }
    // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1ZWZlYTY1OGRiZTBlODRlYjQ2ZTAzMCIsImVtYWlsIjoiIiwibW9iaWxlIjoiNzk5MzU4NjU1MCIsInJvbGUiOiJhZG1pbiIsIm5hbWUiOiIiLCJpYXQiOjE3MTAyMjIwMzN9.2n3X-nibp2rJHFdERQlJInM__AXEjKI5dMtppHwAaQk"

    // setValue(man);
    // setName('Emay Walter');
    // if (email === 'test@gmail.com' && password === 'test123') {
    //   localStorage.setItem('login', JSON.stringify(true));
    //   // localStorage.setItem('token', JSON.stringify(token));
    //   history(`/dashboard`);
    //   toast.success('Successfully logged in!..');
    // } else {
    //   toast.error('You enter wrong password or username!..');
    // }

  };

  useEffect(() => {
    setLoggedUser('Admin');
  }, []);

  return (
    <Fragment>
      <Container fluid={true} className="p-0 login-page">
        <Row className='flex justify-center'>
          {/* <Col xs="4">
            <div className="flex flex-col login-card login-type my-3 max-w-md mx-5 p-6 bg-red-500 text-black">
              {/* This div will have a blue background */}
          {/* <div className="flex items-center mb-4">
                <input
                  type="radio"
                  id="switchAdmin"
                  name="switchPlan"
                  value="Admin"
                  checked={loggedUser === 'Admin'}
                  onChange={() => setLoggedUser('Admin')}
                  className="mr-2"
                />
                <label htmlFor="switchAdmin" className="mt-2 text-sm cursor-pointer">Login as Admin</label>
              </div>
              <div className="flex items-center mb-4">
                <input
                  type="radio"
                  id="switchPartner"
                  name="switchPlan"
                  value="Partner"
                  checked={loggedUser === 'Partner'}
                  onChange={() => setLoggedUser('Partner')}
                  className="mr-2"
                />
                <label htmlFor="switchPartner" className="mt-2 text-sm cursor-pointer">Login as Partner</label>
              </div> */}
          {/* <div className="grid">
      <button className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-3 rounded-lg">Login</button>
    </div> */}
          {/* </div>
          </Col> */}
          <Col xs="12">
            <div className="login-card">
              {/* <div className="max-w-md mx-auto p-6 bg-white border rounded-lg shadow-md">
                <div className="flex items-center mb-4">
                  <input
                    type="radio"
                    id="switchAdmin"
                    name="switchPlan"
                    value="Admin"
                    checked={loggedUser === 'Admin'}
                    onChange={() => setLoggedUser('Admin')}
                    className="mr-2"
                  />
                  <label htmlFor="switchAdmin" className="text-sm cursor-pointer">Login as Admin</label>
                </div>
                <div className="flex items-center mb-4">
                  <input
                    type="radio"
                    id="switchPartner"
                    name="switchPlan"
                    value="Partner"
                    checked={loggedUser === 'Partner'}
                    onChange={() => setLoggedUser('Partner')}
                    className="mr-2"
                  />
                  <label htmlFor="switchPartner" className="text-sm cursor-pointer">Login as Store</label>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <button className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg">Login as Admin</button>
                  <button className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg">Login as Store</button>
                </div>
              </div> */}
              <div className="login-main login-tab flex">
                <div className='min-w-[200px]'>
                  <h1 className="text-center">
                    GRID
                  </h1>
                  <div className="flex flex-col login-type my-3 max-w-md mx-1 p-1 text-black">
                    {/* This div will have a blue background */}
                    <div className="flex items-center mb-4">
                      <input
                        type="radio"
                        id="switchAdmin"
                        name="switchPlan"
                        value="Admin"
                        checked={loggedUser === 'Admin'}
                        onChange={() => setLoggedUser('Admin')}
                        className="mr-2"
                      />
                      <label htmlFor="switchAdmin" className="mt-2 text-sm cursor-pointer">Login as Admin</label>
                    </div>
                    <div className="flex items-center mb-4">
                      <input
                        type="radio"
                        id="switchPartner"
                        name="switchPlan"
                        value="Partner"
                        checked={loggedUser === 'Partner'}
                        onChange={() => setLoggedUser('Partner')}
                        className="mr-2"
                      />
                      <label htmlFor="switchPartner" className="mt-2 text-sm cursor-pointer">Login as Partner</label>
                    </div>
                    {/* <div className="grid">
      <button className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-3 rounded-lg">Login</button>
    </div> */}
                  </div>
                </div>
                <Form className="theme-form min-w-[20px] max-w-md mx-auto">
                  {/* This form will be centered and have a white background */}
                  {/* <h1 className="text-center">
                    GRID
                  </h1> */}
                  {/* {loggedUser && ( */}
                  <Fragment>
                    <FormGroup>
                      <Label className="col-form-label">{EmailAddress}</Label>
                      <Input
                        className="form-control"
                        placeholder="Enter your email"
                        type="email"
                        onChange={(e) => {
                          // console.log(e.target.value, email)
                          setEmail(e.target.value)
                        }}
                        value={email}
                      />
                    </FormGroup>
                    <FormGroup className="position-relative">
                      <Label className="col-form-label">{Password}</Label>
                      <div className="position-relative">
                        <Input
                          className="form-control"
                          placeholder="Password"
                          type={togglePassword ? 'text' : 'password'}
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                        />
                        <div
                          className="show-hide"
                          onClick={() => setTogglePassword(!togglePassword)}
                        >
                          <span className={togglePassword ? '' : 'show'}></span>
                        </div>
                      </div>
                    </FormGroup>
                    <div className="position-relative form-group mb-0">
                      <div className="checkbox">
                        <Input id="checkbox1" type="checkbox" />
                        <Label className="text-muted" for="checkbox1">
                          {RememberPassword}
                        </Label>
                      </div>
                      {/* <div> */}
                      <>
                        <Link to="/forgot-password">{ForgotPassword}</Link>
                      </>
                      {/* </div> */}
                      {/* <a className="link" href="#javascript">
                            {ForgotPassword}
                          </a> */}


                      <Button
                        className='d-block w-100 mt-2'
                        color='primary'
                        onClick={(e) => loginAuth(e)}
                      >
                        {SignIn}
                      </Button>
                    </div>
                  </Fragment>
                  {/* )} */}
                </Form>
              </div>
            </div>
          </Col>
        </Row >
      </Container >
      <ToastContainer />
    </Fragment >
  );
};

export default Signin;
