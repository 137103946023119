import { Badge } from "reactstrap";
import { useDataContext } from "../../Context/hooks/useDataContext";
import moment from "moment";
// const { servicesData } = useDataContext();
export const partnerColumns = [
    {
        name: '#',
        cell: (row, index) => index + 1,
        center: true,
        width: '50px',
    },
    {
        name: 'Name',
        selector: row => row.name,
        center: false,
    },
    {
        name: 'Email',
        selector: row => row.email,
        center: true,
        sortable: true,
    },
    {
        name: 'Role',
        selector: row => row.userType,
        center: true,
        sortable: true,
    },
    {
        name: 'created by',
        selector: row => row.createdBy,
        // selector: async (row) => {
        //     // const userName = await getPartner(row.createdBy);
        //     return userName.name;
        // },
        center: false,
        sortable: true,
    },
    {
        name: 'Joining date',
        // selector: row => row.createdAt,
        selector: row => {
            const date = new Date(row.createdAt);
            const formattedDate = date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' });
            return formattedDate;
        },

        center: false,
        sortable: true,
    },
]

export const taxColumns = [
    {
        name: '#',
        cell: (row, index) => index + 1,
        center: true,
        width: '50px',
    },
    {
        name: 'Fedral Income Tax',
        selector: row => row.fedralIncomeTax,
        center: "true",
    },
    {
        name: 'State Income Tax',
        selector: row => row.stateIncomeTax,
        center: "true",
    },
    {
        name: 'City',
        selector: row => row.city,
        cell: row => row.city.charAt(0).toUpperCase() + row.city.slice(1),
        center: "true",
    },
    {
        name: 'State',
        selector: row => row.state,
        cell: row => row.state.charAt(0).toUpperCase() + row.state.slice(1),
        center: "true",
    },
    {
        name: 'Country',
        selector: row => row.country,
        cell: row => row.country.toUpperCase(),
        // cell: row => row.country.charAt(0).toUpperCase() + row.country.slice(1),
        right: "true",
    }
]

export const couponColumns = [
    {
        name: '#',
        cell: (row, index) => index + 1,
        center: true,
        width: '50px',
    },
    {
        name: 'Coupon Code',
        selector: row => row.name,
        center: "true",
        // maxWidth: 'fit-content',
        // minWidth: '50px',
        // width: '120px'

    },
    {
        name: 'Description',
        selector: row => row.description,
        center: true,
        grow: 0,
        sortable: true,
        wrap: true,
        width: '200px',
        // minWidth: '50px',
        // width: '120px'
        // whitespace: true,
        // wordWrap: true
        //     white- space: normal,
        // word - wrap: break-word
    },
    {
        name: 'Discount',
        selector: row => row.value,
        center: true,
        sortable: true,
        // width: '125px',
    },
    {
        name: 'Status',
        selector: row => row.status,
        cell: row => {
            if (row.status === 'active') {
                return <Badge style={{ fontSize: "12px", width: "60%" }} color="success">Active</Badge>
            }
            else if (row.status === 'expired') {
                return <Badge style={{ fontSize: "12px", width: "60%" }} color="danger">Inactive</Badge>
            }
        },
        sortable: true,
        center: true,
        // conditionalCellStyles: [
        //     {
        //         when: row => row.status === 'active',
        //         style: {
        //             backgroundColor: '#009688',
        //             color: 'white'
        //         }
        //     },
        //     {
        //         when: row => row.status === 'expired',
        //         style: {
        //             backgroundColor: '#f44336',
        //             color: 'white'
        //         }
        //     }
        // ]
    },
    {
        name: 'Valid From',
        selector: row => row.validFrom,
        cell: (row) => {
            const date = new Date(row.validFrom);
            const formattedDate = date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' });
            return formattedDate;
        },
        center: true,
        sortable: true,
    },
    {
        name: 'Expiry',
        selector: row => row.validTill,
        cell: (row) => {
            const date = new Date(row.validTill);
            const formattedDate = date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' });
            return formattedDate;
        },
        center: true,
        sortable: true,
    },
    // {
    //     name: 'Created By',
    //     selector: row => row.createdBy,
    //     center: false,
    //     sortable: true,
    // },
    // {
    //     name: 'Date Created',
    //     selector: row => row.createdAt,
    //     center: false,
    //     sortable: true,
    // },
    // {
    //     name:'Action',
    //     selector: row => (
    //         <div>
    //             <button className="btn btn-primary" onClick={() => handleEdit(row)}>Edit</button>
    //             <button className="btn btn-danger" onClick={() => handleDelete(row)}>Delete</button>
    //         </div>
    //     ),
    //     center: false,
    //     sortable: false,
    // },
    // {
    //     selector: (row) => (
    //         <div className='relative'>
    //             <div>
    //                 <MoreVertical className={`cursor-pointer ${activeDropdown !== null ? 'bg-gray-300' : ""}`}
    //                     onMouseEnter={() => toggleDropdown(row._id)}
    //                     onMouseLeave={() => toggleDropdown(null)}
    //                 />
    //             </div>
    //             {
    //                 activeDropdown === row._id && (
    //                     <div className='relative border-1 border-black-700 right-0 mt-0 mr-0 w-18  rounded-sm shadow-md z-10'>
    //                         <button
    //                             onClick={() => handleDelete(row)}
    //                             className='block px-4 py-2 text-sm text-black-700 hover:bg-gray-100 w-full'>
    //                             View
    //                         </button>
    //                         <button
    //                             onClick={() => handleEdit(row)}
    //                             className='block px-4 py-2 text-sm text-black-700 hover:bg-gray-100 w-full'>
    //                             Edit
    //                         </button>
    //                         <button
    //                             onClick={() => handleDelete(row)}
    //                             className='block px-4 py-2 text-sm text-red-700 hover:bg-gray-100 w-full'>
    //                             Delete
    //                         </button>
    //                         {/* Add more options as needed */}
    //                     </div>
    //                 )
    //             }
    //         </div>
    //     ),
    //     center: true,
    //     sortable: false,
    // },
    // {
    //     name: 'Action',
    //     cell: (row) => (
    //         <div className="d-flex">
    //             <div
    //                 className="cursor-pointer"
    //             >
    //                 <UncontrolledDropdown className='action_dropdown'>
    //                     <DropdownToggle className='action_btn'
    //                     >
    //                         <MoreVertical color='#000' size={16} />
    //                     </DropdownToggle>
    //                     <DropdownMenu>
    //                         <DropdownItem className='block px-4 py-2 text-sm text-black-700 hover:bg-gray-100 w-full' onClick={() => handleEdit(row?._id)}>
    //                             Edit
    //                             {/* <FaPen /> */}
    //                         </DropdownItem>
    //                         <DropdownItem className='delete_item block px-4 py-2 text-sm text-black-700 hover:bg-gray-100 w-full' onClick={() => handleDelete(row)}>
    //                             Delete
    //                             {/* <FaTrashAlt /> */}
    //                         </DropdownItem>
    //                     </DropdownMenu>
    //                 </UncontrolledDropdown>

    //             </div>
    //         </div>
    //     )
    // }
]

export const serviceColumns = [
    {
        name: '#',
        cell: (row, index) => index + 1,
        center: true,
        width: '50px',
    },
    {
        name: 'Name',
        // selector: row => row.type,
        cell: row => row.type ? row.type.charAt(0).toUpperCase() + row.type.slice(1) : row.name.charAt(0).toUpperCase() + row.name.slice(1),
        // {
        //     // if (row.type) {
        //     //     row.type.charAt(0).toUpperCase() + row.type.slice(1)
        //     // } else if (row.name) {
        //     //     row.name.charAt(0).toUpperCase() + row.name.slice(1)
        //     // }
        // },
        right: true,

    },
    {
        name: 'Base Fare',
        selector: row => row.baseFare,
        center: true,
        sortable: true,
    },
    {
        name: 'Per Min',
        selector: row => row.chargesPerMinute,
        center: true,
        sortable: true,
    },
    {
        name: 'Per Mile',
        selector: row => row.chargesPerMile,
        center: false,
        sortable: true,
    },
    {
        name: 'Commission',
        selector: row => row.commissionCharge,
        cell: cell => cell.commissionCharge || 'NA',
        center: true,
        sortable: true,
    },
    {
        name: 'Description',
        selector: row => row.description,
        cell: cell => cell.description || 'No description available',
        center: true,
        sortable: true,
    },
    // {
    //     name:'Action',
    //     selector: row => (
    //         <div>
    //             <button className="btn btn-primary" onClick={() => handleEdit(row)}>Edit</button>
    //             <button className="btn btn-danger" onClick={() => handleDelete(row)}>Delete</button>
    //         </div>
    //     ),
    //     center: false,
    //     sortable: false,
    // },
    // {
    //     selector: (row) => (
    //         <div className='relative'>
    //             <div>
    //                 <MoreVertical className={`cursor-pointer ${activeDropdown !== null ? 'bg-gray-300' : ""}`}
    //                     onMouseEnter={() => toggleDropdown(row._id)}
    //                     onMouseLeave={() => toggleDropdown(null)}
    //                 />
    //             </div>
    //             {
    //                 activeDropdown === row._id && (
    //                     <div className='relative border-1 border-black-700 right-0 mt-0 mr-0 w-18  rounded-sm shadow-md z-10'>
    //                         <button
    //                             onClick={() => handleDelete(row)}
    //                             className='block px-4 py-2 text-sm text-black-700 hover:bg-gray-100 w-full'>
    //                             View
    //                         </button>
    //                         <button
    //                             onClick={() => handleEdit(row)}
    //                             className='block px-4 py-2 text-sm text-black-700 hover:bg-gray-100 w-full'>
    //                             Edit
    //                         </button>
    //                         <button
    //                             onClick={() => handleDelete(row)}
    //                             className='block px-4 py-2 text-sm text-red-700 hover:bg-gray-100 w-full'>
    //                             Delete
    //                         </button>
    //                         {/* Add more options as needed */}
    //                     </div>
    //                 )
    //             }
    //         </div>
    //     ),
    //     center: true,
    //     sortable: false,
    // },
]

export const driverColumns = [
    // {
    //     name: '#',
    //     cell: (row, index) => index + 1,
    //     center: true,
    //     width: '50px',
    // },
    {
        name: 'Driver Name',
        selector: row => row.name,
        // cell: (row, index) => row.name || "Tony" + index,
        cell: (row, index) => {
            const driverName = row.name.toLowerCase();
            if (driverName.includes('driver')) {
                const remainingPart = row.name.replace(/driver/gi, '').trim();
                return remainingPart.charAt(0).toUpperCase() + remainingPart.slice(1) || "Tony" + index;
            }

            // If the name does not include "driver", return the original name
            return row.name.charAt(0).toUpperCase() + row.name.slice(1) || "No Name";
        },
        sortable: true,
        center: true,
        searchable: true,
        // maxWidth: 'fit-content',

        // width: auto
    },
    {
        name: "Mobile",
        selector: row => row.mobile,

    },
    {
        name: "Vehicle",
        selector: row => row?.vehicle?.brand || "Not Assigned",
        cell: row => {
            if (row?.vehicle?.brand) {
                const vehicleBrand = row.vehicle.brand.charAt(0).toUpperCase() + row.vehicle.brand.slice(1);
                // if (row.vehicle.cabModel) {
                const vehicleCabModel = row?.vehicle?.cabModel ? row.vehicle.cabModel.charAt(0).toUpperCase() + row.vehicle.cabModel.slice(1) : "";
                // }
                return vehicleBrand + ' ' + vehicleCabModel
            }
            else return "Not Assigned"

            // row?.vehicle?.brand ? row.vehicle.brand.charAt(0).toUpperCase() + row.vehicle.brand.slice(1) : "Not Assigned"
        },
        sortable: true,
        center: true,
        searchable: true,
        // maxWidth: 'fit-content',}
    },
    {
        name: "Email",
        selector: row => row.email,
        grow: 0,
        sortable: true,
        wrap: true,
        width: '250px'
    },
    // {
    //     name: "Gender",
    //     selector: row => row.gender
    // },
    {
        name: "Ratings",
        selector: row => row.ratings,
        cell: cell => cell.ratings || 'NA',
        center: true,
        sortable: true,
    },
    // {
    //     name: "Date of Birth",
    //     selector: row => Date(row.dob),
    //     cell: row => row.dob ? row.dob.slice(0, 10) : '',
    //     center: true,
    //     sortable: true,
    // },
    {
        name: "Emergency",
        selector: row => row.emergencyContact,
        cell: row => row.emergencyContact ? row.emergencyContact.slice(0, 10) : 'NA',
        center: true,
        sortable: false
    },
    // {
    //     name: "Deleted",
    //     selector: row => row.isDeleted,
    //     cell: row => row.isDeleted ? row.isDeleted.slice(0, 10) : 'NA',
    //     center: true,
    //     sortable: false
    // },
    // {
    //     name: "Vehicle",
    //     selector: row => row.vehicle,
    //     cell: row => row.vehicle ? row.vehicle.brand : 'NA',
    //     center: true,
    //     sortable: false
    // },
    // {
    //     name: 'Action',
    //     cell: (row) => (
    //         <div className="d-flex">
    //             <div
    //                 className="cursor-pointer"
    //             >
    //                 <UncontrolledDropdown className='action_dropdown'>
    //                     <DropdownToggle className='action_btn'
    //                     >
    //                         <MoreVertical color='#000' size={16} />
    //                     </DropdownToggle>
    //                     <DropdownMenu>
    //                         <DropdownItem>
    //                             Edit
    //                             {/* <FaPen /> */}
    //                         </DropdownItem>
    //                         <DropdownItem className='delete_item'>
    //                             Delete
    //                             {/* <FaTrashAlt /> */}
    //                         </DropdownItem>
    //                     </DropdownMenu>
    //                 </UncontrolledDropdown>

    //             </div>
    //         </div>
    //     )
    // }
]

export const vehicleColumns = [
    {
        name: 'Vehicle Name',
        selector: row => row.brand,
        sortable: true,
        // center: true
        left: true
    },
    {
        name: 'Model',
        selector: row => row.cabModel,
        sortable: true,
        center: true
    },
    {
        name: 'Reg Number',
        selector: row => row.regNumber,
        sortable: true
    },
    // {
    //     name: 'Vehicle Type',
    //     selector: row => row.serviceType,
    //     sortable: true
    // },
    {
        name: 'Driver ID',
        selector: row => row.driverId,
        sortable: true
    },
    {
        name: 'Ac Ride',
        selector: row => row.acRide,
        cell: row => {
            if (row.acRide === true) {
                return "true";
            }
            else if (row.acRide === true) {
                return "false";
            }
            else return "NA";
        },
        sortable: true
    },
    // {
    //     name: 'Action',
    //     // selector: 'action',
    //     sortable: true
    // }
]

export const tripColumns = [
    // {
    //     name: 'Trip ID',
    //     selector: row => row._id,
    //     sortable: true
    // },
    {
        name: 'Driver name',
        selector: row => row?.driver?.name,
        // selector: row => row.driver.map(driver => driver.name).join(', '),
        // cell: row => row.driver[0].name,
        sortable: true,
        center: true,
        grow: 0,
        wrap: true,
        width: '140px',
    },
    {
        name: "Source",
        selector: row => row.source.address,
        center: true,
        grow: 0,
        wrap: true,
        width: '200px',
        sortable: true

    },
    {
        name: "Destination",
        selector: row => row.destination.address,
        center: true,
        grow: 0,
        wrap: true,
        width: '200px',
        sortable: true
    },
    {
        name: "Start Date",
        selector: row => {
            const formatDate = (row.trip_begin)
            if (formatDate === null) {
                return "NA"
            } else {
                return moment(formatDate).format('YYYY/MM/DD HH:mm A')

            }
        },
        cell: row => {
            const formattedDate = moment(row.trip_begin).format('DD-MM-YYYY');
            const formattedTime = moment(row.trip_begin).format('hh:mm A');

            return (
                <div className="ml-[-4]">
                    <div className="mb-2">{formattedDate}</div>
                    <div>{formattedTime}</div>
                </div>
            );
        },
        center: true,
        grow: 0,
        wrap: true,
        width: '120px',
        sortable: true
    },
    {
        name: "End Date",
        selector: row => {
            const formatDate = (row.trip_end)
            if (formatDate === null) {
                return "NA"
            }
            else {
                return moment(formatDate).format('YYYY/MM/DD hh:mm A')
            }
        },
        cell: row => {
            const formattedDate = moment(row.trip_end).format('DD-MM-YYYY');
            const formattedTime = moment(row.trip_end).format('hh:mm A');

            return (
                <div>
                    <div className="mb-2">{formattedDate}</div>
                    <div>{formattedTime}</div>
                </div>
            );
        },
        center: true,
        grow: 0,
        wrap: true,
        width: '120px',
        sortable: true
    },
    {
        name: "Status",
        selector: row => row.status.charAt(0).toUpperCase() + row.status.slice(1),
        sortable: true,
        left: true,
        width: "150px",
        cell: row => {
            let statusColor = '';
            if (row.status === "completed") {
                statusColor = 'text-green-600';
            } else if (row.status === "cancelled") {
                statusColor = 'text-red-600';
            } else if (row.status === "pending") {
                statusColor = 'text-black-700';
            } else if (row.status === "started") {
                statusColor = 'text-orange-600';
            }

            return (
                <span className={statusColor} title={row.status}>
                    {row.status.charAt(0).toUpperCase() + row.status.slice(1)}
                </span>
            )
            // if (row.status === "completed") {
            //     return (
            //         <span className="text-green-500">
            //             {row.status}
            //         </span>
            //     )
            // }
        }
    },
    // {
    //     name: "Service",
    //     selector: row => row.serviceType,
    //     sortable: true
    // },
    {
        name: "Total Fare",
        selector: row => "$ " + (row.price.total).toFixed(2), //MAth
        center: true,
        grow: 0,
        wrap: true,
        width: '120px',
        sortable: true
    },
    {
        name: "Payment type",
        selector: row => row.paymentType,
        center: true,
        grow: 0,
        wrap: true,
        width: '150px',
        sortable: true
    }
]
