import React, { Fragment, useCallback, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { Btn, H4 } from '../../../AbstractElements';
import { dummytabledata, tableColumns } from '../../../Data/Table/Defaultdata';
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import axios from 'axios';
import { baseURL } from '../../../Services/baseURL';
import endPoints from '../../../Services/EndPoints';
import * as Storage from '../../../store/LocalStorage';
import { useDataContext } from '../../../Context/hooks/useDataContext';
import { driverColumns } from '../../DataTableCols';
import { useNavigate } from 'react-router';
import { Edit, MoreVertical, PlusCircle, Trash2 } from 'react-feather';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import LogoutModal from '../../Main/reuseModal';
// import { driverColumns } from '../../DataTableCols';

const DriverTableComponent = () => {

    const [searchTerm, setSearchTerm] = useState("")
    const { isModalOpen, toggleModal } = useDataContext()
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);

    const [selectedRows, setSelectedRows] = useState([]);
    // const [toggleDelet, setToggleDelet] = useState(false);
    const [data, setData] = useState(dummytabledata);
    const [alert, setAlert] = useState(false)

    const navigate = useNavigate()
    const { driversData, setDriversData } = useDataContext()

    const token = JSON.parse(Storage.get("token"))

    const fetchDriverList = async () => {
        try {
            setLoading(true)
            let params = {
                skip: (currentPage - 1) * perPage,
                limit: perPage,
            }

            const response = await axios.get(`${baseURL}${endPoints.getAllDrivers}?skip=0&limit=2000`, {
                headers: {
                    Authorization: `${token}`,
                }
                // params: params
            });
            if (response?.status === 200 && response) {
                setDriversData(response.data.data)
                setTotalRows(response.data.totalRecord)
                setLoading(false)
            }
        } catch (e) {
            console.error(e)
            setLoading(false)
            if (e?.response?.status === 403) {
                toggleModal(true)
            }
        }
    }

    useEffect(() => {
        fetchDriverList();
    }, [currentPage, perPage]);

    // const handleSearch = (e) => {
    //     setSearchTerm(e.target.value)
    // }

    const handleAddBtn = () => {
        navigate("/driver/create")
    }

    if (searchTerm !== "") {
        data = driversData.filter((item) => {
            // console.log(item.name)
            return item.name.toLowerCase().includes(searchTerm.toLowerCase())
            // })/
        })
    }
    // console.log(`rows ${totalRows}, search term ${searchTerm}, data ${data}`)

    const handlePageChange = (currentPage) => {
        // console.log(`Page ${currentPage}`)
        // console.log(`rows ${totalRows}`)
        setCurrentPage(currentPage);
    };

    const handlePerRowsChange = async (perPage, currentPage) => {
        // console.log(`Page ${perPage}, page ${currentPage}`)
        setPerPage(perPage);
        setCurrentPage(currentPage)
    };

    const handleRowSelected = useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);

    const handleEdit = (id) => {
        navigate(`/driver/edit/${id}`)
    }

    const handleDelete = async (row) => {
        if (window.confirm(`Are you sure you want to delete:\r ${row.name}?`)) {
            // setToggleDelet(!toggleDelet);
            const confirmed = await Swal.fire({
                title: `Are you sure you want to delete:\r ${row.name}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            });
            if (confirmed.isConfirmed) {
                try {
                    const response = await axios.delete(`${baseURL}/admin/deleteDriver/${row._id}`, {
                        headers: {
                            Authorization: `${token}`,
                        }
                    })
                    if (response?.status === 200 && response) {
                        setDriversData(driversData.filter(item => item._id !== row._id))
                        Swal.fire({
                            icon: 'success',
                            title: 'Deleted!',
                            text: "Driver data deleted successfully",
                            showConfirmButton: false,
                            timer: 1600
                        });
                    }
                } catch (e) {
                    console.error(e)
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: "Something went wrong",
                        showConfirmButton: false,
                        timer: 1600
                    });
                }
            }
        }
    };

    // const driverColumns = [
    //     {
    //         name: '#',
    //         cell: (row, index) => (currentPage - 1) * perPage + index + 1,
    //         left: true,
    //         maxWidth: 'fit-content',
    //         // minWidth: '60px'
    //         // maxWidth: '6px',
    //         // minWidth: '40px'
    //     },
    //     {
    //         name: "No.",
    //         selector: row => row.id,
    //         sortable: true
    //     },
    //     {
    //         name: 'Driver Name',
    //         selector: row => row.name,
    //         sortable: true,
    //         center: true,
    //         // width: auto
    //     },
    //     {
    //         name: "Mobile",
    //         selsector: row => row.mobile,

    //     },
    //     {
    //         name: "Email",
    //         selector: row => row.email,
    //     },
    //     {
    //         name: "Ratings",
    //         selector: row => row.ratings,
    //     },
    //     {

    //     }
    //     // {
    //     //     name: 'Action',
    //     //     cell: (row) => (
    //     //         <div className="d-flex">
    //     //             <div
    //     //                 className="cursor-pointer"
    //     //             >
    //     //                 <UncontrolledDropdown className='action_dropdown'>
    //     //                     <DropdownToggle className='action_btn'
    //     //                     >
    //     //                         <MoreVertical color='#000' size={16} />
    //     //                     </DropdownToggle>
    //     //                     <DropdownMenu>
    //     //                         <DropdownItem>
    //     //                             Edit
    //     //                             {/* <FaPen /> */}
    //     //                         </DropdownItem>
    //     //                         <DropdownItem className='delete_item'>
    //     //                             Delete
    //     //                             {/* <FaTrashAlt /> */}
    //     //                         </DropdownItem>
    //     //                     </DropdownMenu>
    //     //                 </UncontrolledDropdown>

    //     //             </div>
    //     //         </div>
    //     //     )
    //     // }
    // ]

    const actionColumn = [
        {
            name: 'Action',
            selector: row => row.action,
            cell: (row) => (
                <div className="d-flex">
                    <div className="cursor-pointer">
                        <UncontrolledDropdown className='action_dropdown'>
                            <DropdownToggle className='action_btn'
                            >
                                <MoreVertical color='#000' size={16} />
                            </DropdownToggle>
                            <DropdownMenu style={{ backgroundColor: "#7366ff" }}>
                                <DropdownItem className='d-flex align-items-center justify-between block px-4 py-2 text-sm text-black-700 hover:bg-gray-100 w-full' onClick={() => handleEdit(row?._id)}>
                                    Edit
                                    <Edit className="w-5 h-5 " />
                                </DropdownItem>
                                <DropdownItem className='d-flex align-items-center justify-between delete_item block px-4 py-2 text-sm text-black-700 hover:bg-gray-100 w-full' onClick={() => handleDelete(row)}>
                                    Delete
                                    <Trash2 className="w-5 h-5 " />
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </div>
            ),
            center: true,
            sortable: false,
        }
    ]

    const indexCol = [
        {
            name: '#',
            // cell: (row, index) => index + 1,
            cell: (row, index) => {
                // Calculate the start index based on the current page and items per page
                const startIndex = (currentPage - 1) * perPage + index + 1;
                // Calculate the end index
                // const endIndex = startIndex + perPage - 1;
                // Display the range of indices
                return startIndex;
            },
            center: true,
            width: '50px',
        },
    ]

    const driverCol = indexCol.concat(driverColumns)

    const AllColumns = driverCol.concat(actionColumn)


    return (
        <Fragment>
            <Row xxl={12} className="pb-4">
                <Row>
                    <Col md={12}>
                        <div className="flex justify-between">
                            <h1>Manage Driver</h1>
                            {
                                // userRole === 'admin' &&
                                <Button className='btn btn-secondary-gradien d-flex flex-end align-items-center ms-3'
                                    onClick={handleAddBtn} >
                                    <PlusCircle className="w-6 h-6 mr-2" />
                                    Add New Driver
                                </Button>
                            }
                        </div>
                    </Col>
                </Row>
            </Row>

            {
                driversData && driversData.length !== 0 ? (
                    <DataTable
                        // title="drivers"
                        data={driversData}
                        columns={AllColumns}
                        center={true}
                        pagination
                        // paginationServer
                        // paginationTotalRows={totalRows}
                        progressPending={loading}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                    // searchable
                    // onSelectedRowsChange={} 
                    />
                ) : (
                    <Fragment>
                        <div className={`d-flex align-items-center justify-content-between bg-light-info p-2`}>
                            <H4 attrH4={{ className: 'text-muted m-0' }}>No Data Found..!</H4>
                        </div>
                    </Fragment>
                )
            }
            {isModalOpen && <LogoutModal />}
        </Fragment>
    )
}
export default DriverTableComponent