import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Row, Col, Button } from "reactstrap";
import { baseURL } from "../../../Services/baseURL";
import endPoints from "../../../Services/EndPoints";
import * as Storage from "../../../store/LocalStorage";
import { useDataContext } from "../../../Context/hooks/useDataContext";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { PlusCircle, MoreVertical, Edit, Trash2 } from "react-feather";
import { taxColumns } from "../../DataTableCols";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import LogoutModal from "../reuseModal";

const TaxesTable = () => {

    const { taxData, setTaxData, isModalOpen, toggleModal } = useDataContext()

    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);

    const token = JSON.parse(Storage.get("token"))

    const fetchTaxesList = async () => {
        try {
            setLoading(true);
            let URL = `${baseURL}${endPoints.getAllTaxes}`
            const response = await axios.get(URL,
                {
                    headers: {
                        Authorization: `${token}`
                    }
                });
            if (response?.status === 200 && response) {
                setTaxData(response.data.data);
                setLoading(false);
                setTotalRows(response.data.totalRecord);
            }
        }
        catch (error) {
            console.error(error)
            if (error.response.status === 403) {
                toggleModal(true)
            }
        }
    }

    useEffect(() => {
        fetchTaxesList();
    }, [currentPage, perPage])

    const navigate = useNavigate()

    const handlePageChange = (currentPage) => {
        setCurrentPage(currentPage);
    };

    const handlePerRowsChange = async (perPage, currentPage) => {
        setPerPage(perPage);
        setCurrentPage(currentPage)
    };

    const handleEdit = (id) => {
        navigate(`/taxes/editTax/${id}`)
    }

    const handleDelete = async (id) => {
        const confirmDelete = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        })

        if (confirmDelete.isConfirmed) {
            try {
                await axios.delete(`${baseURL}${endPoints.deleteTax}${id}`, {
                    headers: {
                        Authorization: `${token}`,
                    }
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Deleted!',
                    text: "Tax data deleted successfully",
                    showConfirmButton: false,
                    timer: 1600
                });
                setTaxData(taxData.filter(tax => tax._id !== id))
            }
            catch (error) {
                console.error(error, "serivces error")
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: "Something went wrong",
                    showConfirmButton: false,
                    timer: 1600
                });
            }
        }
        // const response = await deleteService({}, token)
    }

    const actionColumn = [
        {
            name: 'Action',
            selector: row => row.action,
            cell: (row) => (
                <div className="d-flex">
                    <div className="cursor-pointer">
                        <UncontrolledDropdown className='action_dropdown'>
                            <DropdownToggle className='action_btn'
                            >
                                <MoreVertical color='#000' size={16} />
                            </DropdownToggle>
                            <DropdownMenu style={{ backgroundColor: "#7366ff" }}>
                                <DropdownItem className='d-flex align-items-center justify-between block px-4 py-2 text-sm text-black-700 hover:bg-gray-100 w-full' onClick={() => handleEdit(row?._id)}>
                                    Edit
                                    <Edit className="w-5 h-5 " />
                                </DropdownItem>
                                <DropdownItem className='d-flex align-items-center justify-between delete_item block px-4 py-2 text-sm text-black-700 hover:bg-gray-100 w-full' onClick={() => handleDelete(row?._id)}>
                                    Delete
                                    <Trash2 className="w-5 h-5 " />
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </div>
            ),
            center: true,
            sortable: false,
            // width: 'auto',
            // maxWidth: 'none',
            // minWidth: '50px',
            // grow: 1,
            // conditionalCellStyles: [
            //     {
            //         when: cell => cell && cell.value,
            //         style: cell => ({
            //             width: `${cell.value.length * 10}px`
            //         })
            //     }
            // ]

        }
    ]

    const allColumns = taxColumns.concat(actionColumn)


    return (
        <Fragment>
            <Row xxl={12} className="pb-4">
                {/* <Row>
                    <Col md={12}>
                        <div className="flex justify-between">
                            <div className="flex">
                                <h4 className="text-2xl font-semibold">Manage Taxes</h4>
                            </div>
                            <div className="flex">
                                <button className="d-flex btn btn-secondary-gradien"
                                    onClick={() => navigate("/addTax")}
                                >
                                    <PlusCircle className="mr-2" />
                                    Add New Tax</button>
                            </div>
                        </div>
                    </Col>
                </Row> */}
                <Row xxl={12} className="pb-4">
                    <Row>
                        <Col md={12}>
                            <div className="flex justify-between">
                                <h2>Manage Tax Rates</h2>
                                {
                                    <Button className='btn btn-secondary-gradien d-flex flex-end align-items-center ms-3'
                                        onClick={() => navigate("/addTax")}
                                    >
                                        <PlusCircle className="w-6 h-6 mr-2" />
                                        Add Tax Rate
                                    </Button>
                                }
                            </div>
                        </Col>
                    </Row>
                </Row>
                {taxData && taxData.length !== 0 ? (
                    <DataTable
                        columns={allColumns}
                        data={taxData}
                        highlightOnHover
                        striped
                        responsive
                        dense
                        progressPending={loading}
                        pagination
                        paginationServer
                        paginationTotalRows={taxData.length}
                    />
                ) : (
                    <Fragment>
                        <Row style={{ minHeight: "250px" }}>
                            <Col md={12} className="flex justify-center">
                                <div className="text-center p-5">
                                    <h1 className="text-muted">No data available</h1>
                                </div>
                            </Col>
                        </Row>
                    </Fragment>
                )}
                {isModalOpen && <LogoutModal />}
            </Row>
        </Fragment >
    );
}

export default TaxesTable;