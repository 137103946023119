import { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { PlusCircle, MoreVertical, Edit, Trash2 } from "react-feather";
import { Button, Col, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { useNavigate } from "react-router-dom"
import axios from "axios";
import * as Storage from '../../../store/LocalStorage'
import 'react-dropdown'
import { baseURL } from "../../../Services/baseURL";
import endPoints from "../../../Services/EndPoints";
import { useDataContext } from "../../../Context/hooks/useDataContext";
import { serviceColumns } from "../../DataTableCols";
import Swal from "sweetalert2";
import LogoutModal from "../reuseModal";

const ServicesTable = () => {

    const { servicesData, setServicesData, isModalOpen, toggleModal } = useDataContext()

    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const navigate = useNavigate()
    const token = JSON.parse(Storage.get("token"))
    // const userRole = Storage.get("role_name")

    const fetchServicesList = async () => {
        try {
            setLoading(true);
            let params = {
                // page: currentPage,
                skip: (currentPage - 1) * rowsPerPage,
                limit: rowsPerPage
            }
            const response = await axios.get(`${baseURL}${endPoints.getServices}`, {
                headers: {
                    Authorization: `${token}`,
                },
                params: params
            })
            if (response?.status === 200) {
                setServicesData(response.data.data)
                setTotalRows(response.data.totalRecord)
                setLoading(false)
            }
        }
        catch (error) {
            console.error(error, "Error fetching services")
            if (error.response.status === 403) {
                toggleModal(true)
            }

        }
    }
    // console.log(totalRows)

    useEffect(() => {
        fetchServicesList()
    }, [currentPage, rowsPerPage])

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (page, newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(page);
    };


    const handleAddBtn = () => {
        navigate("/fleet/addnewservice")
    }
    const handleEdit = (id) => {
        navigate(`/fleet/editservice/${id}`)
    }

    const handleDelete = async (row) => {

        const confirmed = await Swal.fire({
            title: `Are you sure, you want to delete service type "${(row.type).toUpperCase()}" ?`,
            text: "You won't be able to revert this action!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        })

        if (confirmed.isConfirmed) {
            try {
                await axios.delete(`${baseURL}${endPoints.deleteService}${row._id}`, {
                    headers: {
                        Authorization: `${token}`,
                    }
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Deleted!',
                    text: `${row.type} coupon has been deleted`,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                })
                setServicesData(servicesData.filter(service => service._id !== row._id))
            }
            catch (error) {
                console.error("Error deleting service:", error)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong',
                    showConfirmButton: false,
                    timer: 1600
                })
            }
        }
    }

    const actionColumn = [
        {
            name: 'Action',
            selector: row => row.action,
            cell: (row) => (
                <div className="d-flex">
                    <div className="cursor-pointer">
                        <UncontrolledDropdown className='action_dropdown'>
                            <DropdownToggle className='action_btn'
                            >
                                <MoreVertical color='#000' size={16} />
                            </DropdownToggle>
                            <DropdownMenu style={{ backgroundColor: "#7366ff" }}>
                                <DropdownItem className='d-flex align-items-center justify-between block px-4 py-2 text-sm text-black-700 hover:bg-gray-100 w-full' onClick={() => handleEdit(row?._id)}>
                                    Edit
                                    <Edit className="w-5 h-5 " />
                                </DropdownItem>
                                <DropdownItem className='d-flex align-items-center justify-between delete_item block px-4 py-2 text-sm text-black-700 hover:bg-gray-100 w-full' onClick={() => handleDelete(row)}>
                                    Delete
                                    <Trash2 className="w-5 h-5 " />
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </div>
            ),
            center: true,
            sortable: false,
        }
    ]

    const allColumns = serviceColumns.concat(actionColumn)

    return (
        <Fragment>
            <Row xxl={12} className="pb-4">
                <Row>
                    <Col md={12}>
                        <div className="flex justify-between">
                            <h2>Manage Service Type</h2>
                            {
                                // userRole === 'admin' &&
                                <Button className='btn btn-secondary-gradien d-flex flex-end align-items-center ms-3'
                                    onClick={handleAddBtn} >
                                    <PlusCircle className="w-6 h-6 mr-2" />
                                    Add New Service
                                </Button>
                            }
                        </div>
                    </Col>
                </Row>
            </Row>
            {servicesData && servicesData.length !== 0 ? (
                <DataTable
                    // className="data-table"
                    columns={allColumns}
                    data={servicesData}
                    //  title="Manage Fleet"
                    center={true}
                    highlightOnHover
                    striped
                    responsive
                    dense
                    pagination
                    // paginationServer
                    progressPending={loading}
                    // paginationTotalRows={totalRows}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                />
            ) : (
                <Fragment>
                    <Row style={{ minHeight: "250px" }}>
                        <Col md={12} className="flex justify-center">
                            <div className="text-center p-5">
                                <h1 className="text-muted">No Services Available</h1>
                            </div>
                        </Col>
                    </Row>
                </Fragment>
            )}
            {isModalOpen && <LogoutModal />}
        </Fragment>
    );
}

export default ServicesTable;