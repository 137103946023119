export const MENUITEMS = [
  {
    menutitle: 'Menu',
    Items: [
      {
        title: 'Dashboard',
        icon: 'home',
        type: 'link',
        // badge: 'badge badge-light-primary',
        // badgetxt: '5',
        active: false,
        path: `dashboard`,
      },
      // {
      //   title: 'Drivers',
      //   icon: 'driver',
      //   type: 'sub',
      //   bookmark: true,
      //   active: false,
      //   children: [
      //     {
      //       path: `drivers/manage`,
      //       title: 'Manage',
      //       type: 'link',
      //     },
      //     {
      //       path: `drivers/onboarding`,
      //       title: 'OnBoard',
      //       type: 'link',
      //     },
      //     {
      //       path: `drivers/blacklist`,
      //       title: 'Blacklist',
      //       type: 'link',
      //     },
      //   ],
      // },
      {
        title: 'Drivers',
        icon: 'driver',
        type: 'link',
        path: `drivers/manage`,
        // title: 'Manage',
        // type: 'link',
        // bookmark: true,
        // active: false,
        // children: [
        //   {
        //     path: `drivers/manage`,
        //     title: 'Manage',
        //     type: 'link',
        //   },
        //   {
        //     path: `drivers/onboarding`,
        //     title: 'OnBoard',
        //     type: 'link',
        //   },
        //   {
        //     path: `drivers/blacklist`,
        //     title: 'Blacklist',
        //     type: 'link',
        //   },
        // ],
      },
      {
        title: 'Vehicles',
        type: 'link',
        icon: 'vehicles',
        // active: false,
        path: `vehicles`,
        // icon: 'vehicles',
      },
      {
        title: 'Services Types',
        type: 'link',
        path: `fleet`,
        // icon: 'vehicles',
        icon: 'others',
      },
      {
        title: 'Taxes',
        icon: 'editors',
        path: `allTaxes`,
        type: 'link',
      },
      {
        title: 'Coupons',
        icon: 'task',
        path: `coupons`,
        type: 'link',
      },
      {
        title: 'Partners',
        icon: 'learning',
        path: `partners`,
        type: 'link',
      },
      // {
      //   title: 'Notify',
      //   icon: 'user',
      //   type: 'sub',
      //   bookmark: true,
      //   active: false,
      //   children: [
      //     {
      //       path: `notifyDriver`,
      //       type: 'link',
      //       title: 'Notify Driver',
      //     },
      //   ],
      // },

      {
        path: `trips`,
        icon: 'icons',
        type: 'link',
        title: 'Trips',
      },
      // {
      //   path: `payments`,
      //   icon: 'payments',
      //   type: 'link',
      //   title: 'Payments',
      // },
      // {
      //   path: `app/bookmark`,
      //   icon: 'notifications',
      //   type: 'link',
      //   title: 'Notifications',
      // },
      // {
      //   path: `app/bookmark`,
      //   icon: 'tickets',
      //   type: 'link',
      //   title: 'Tickets',
      // },
      // {
      //   path: `${process.env.PUBLIC_URL}/app/bookmark`,
      //   icon: 'driver',
      //   type: 'link',
      //   title: 'GeoServices',
      // },
      // {
      //   path: `${process.env.PUBLIC_URL}/app/bookmark`,
      //   icon: 'driver',
      //   type: 'link',
      //   title: 'User Alerts',
      // },
      // {
      //   path: `${process.env.PUBLIC_URL}/app/bookmark`,
      //   icon: 'settings',
      //   type: 'link',
      //   title: 'Settings',
      // },
    ],
  }
];
