import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuthContext } from "../Context/Auth";

const PrivateRoute = () => {
  const [login, setLogin] = useState(JSON.parse(localStorage.getItem("login")));
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
    localStorage.setItem("authenticated", authenticated);
    localStorage.setItem("login", login);
  }, []);
  // console.log(authenticated, login)
  return login || authenticated ? <Outlet /> : <Navigate exact to={`/login`} />;
};

export default PrivateRoute;
// import { useAuthContext } from "../Context/Auth";
// const PrivateRoute = () => {
//   const [login, setLogin] = useState(true);
// ///////////////// Cahnfe this
//   //////////
//   // setLogin(storedLogin);
//   const [storedToken, setStoredToken] = useState(localStorage.getItem("login"))
//   const { authenticated } = useAuthContext()

//   useEffect(() => {
//     if (storedToken !== null) {
//       setLogin(true);
//     }
//   }, []);

//   console.log("Login", login, "auth", authenticated, storedToken)
//   return login || authenticated ? <Outlet /> : <Navigate exact to={`/login`} />;
// };

// export default PrivateRoute;
