import { createContext, useContext,  useReducer } from "react"
import * as Storage from '../store/LocalStorage'

const INITIAL_STATE = {
    user: null,
    authenticated: false
}
// console.log(INITIAL_STATE)
const AuthContext = createContext({
    ...INITIAL_STATE,
    logout: () => Promise.resolve(),
    updateUserContext: () => Promise.resolve(),
})

const AuthReducer = (state, action) => {
    switch (action.type) {
        case "UPDATE":
            return {
                ...state,
                user: action.payload,
                authenticated: true
            }
        case "LOGOUT":
            return {
                ...state,
                user: null,
                authenticated: false
            }
        default:
            return state
    }
}

const AuthProvider = (props) => {
    const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE)
    const logout = () => {
        dispatch({ type: "LOGOUT" })
        Storage.remove(Storage.sessionKey)
    };
    const updateUserContext = (data) => {
        dispatch({ type: "UPDATE", payload: data });
    }

    return (
        <AuthContext.Provider value={{ ...state, logout, updateUserContext }}>
            {props.children}
        </AuthContext.Provider>
    )
}

function useAuthContext() {
    return useContext(AuthContext);
}
export { AuthContext, AuthProvider, useAuthContext }
