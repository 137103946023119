import { Fragment } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PartnersTable from "./DataTableComponent";


const ManagePartners = () => {

    return (
        <Fragment>
            <Container fluid={true} style={{ padding: '25px', minHeight: '150px' }}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody style={{ minHeight: '600px' }}>
                                <PartnersTable />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default ManagePartners;