import React, { useContext, useState } from 'react';
import { Grid } from 'react-feather';
import { Link } from 'react-router-dom';
import { Image } from '../../AbstractElements';
import CubaIcon from '../../assets/images/logo/logo.png';
import CustomizerContext from '../../_helper/Customizer';

const SidebarLogo = () => {
  const { mixLayout, toggleSidebar, toggleIcon, layout, layoutURL } =
    useContext(CustomizerContext);

  const openCloseSidebar = () => {
    toggleSidebar(!toggleIcon);
  };

  const layout1 = localStorage.getItem('sidebar_layout') || layout;

  return (
    <div className="logo-wrapper">
      {layout1 !== 'compact-wrapper dark-sidebar' &&
      layout1 !== 'compact-wrapper color-sidebar' &&
      mixLayout ? (
        <Link to={`/dashboard`}>
          {/* <Image
            attrImage={{
              className: 'img-fluid d-inline',
              src: `${CubaIcon}`,
              alt: '',
            }}
          /> */}
          <h1 className='img-fluid d-inline' style={{'margin-left':'50px','text-decoration':'italic','color':'blue',  'font-size': '48px', 'font-style': 'italic'}} >GRiD</h1>
          {/* <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 100 100" width="100" height="100">
            <text x="0" y="50" font-family="Arial" style={{className: 'img-fluid d-inline'}} font-size="50" font-weight="bold" font-style="italic" fill="blue">GRiD</text>
          </svg> */}

        </Link>
      ) : (
        <Link to={`/dashboard`}>
          {/* <Image
            attrImage={{
              className: 'img-fluid d-inline',
              src: `${require('../../assets/images/logo/logo_dark.png')}`,
              alt: '',
            }}
          /> */}
          <h1 className='img-fluid d-inline' style={{'margin-left':'5px'}}>GRiD</h1>

        </Link>
      )}
      {/* <div className="back-btn" onClick={() => openCloseSidebar()}>
        <i className="fa fa-angle-left"></i>
      </div>
      <div className='toggle-sidebar' onClick={openCloseSidebar}>
        <Grid className='status_toggle middle sidebar-toggle' />
      </div> */}
    </div>
  );
};

export default SidebarLogo;
