import { useNavigate, useParams } from "react-router"
import * as Storage from '../../../../store/LocalStorage'
import { Fragment, useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Label, Row } from "reactstrap"
import { Btn, H4 } from "../../../../AbstractElements"
import { useForm } from "react-hook-form"
import axios from "axios"
import Swal from "sweetalert2"
import { baseURL } from "../../../../Services/baseURL"
import { useDataContext } from "../../../../Context/hooks/useDataContext"


const CreateDriver = () => {

    const { id } = useParams();
    // console.log(id, "ads")
    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        setValue
    } = useForm();

    const { driversData } = useDataContext();

    const [formData, setFormData] = useState(null);

    const token = JSON.parse(Storage.get('token'))

    const fetchDriverById = async () => {
        try {
            // const response = await axios.get(`${baseURL}/admin/updateDriver/${id}`, {
            //     headers: {
            //         'Authorization': `${token}`
            //     }
            // });
            // if (response.status === 200) {
            //     setFormData(response.data)
            // }

            const driver = driversData.find(driver => driver._id === id);
            if (!driver) return; // Return if driver with the specified ID is not found

            // Set the formData state with the data of the found driver
            setFormData(driver);
        }
        catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        if (id) {
            fetchDriverById();
        }
    }, [])

    useEffect(() => {
        if (id) {
            if (formData && setValue) {
                Object.keys(formData).forEach(key => {
                    if (key in formData) {
                        // if (key === 'validFrom' || key === 'validTill') {
                        //     const formattedDate = formData[key].substring(0, 18);
                        //     setValue(key, formattedDate);
                        // } else {
                        setValue(key, formData[key])
                        // }
                    }
                });
            }
        }
    }, [formData, setValue])

    const onSubmit = async (data) => {
        // console.log(data)
        const reqObj = {
            name: data.name,
            mobile: data.mobile,
            email: data.email,
            countryCode: data.countryCode
        }
        // const reqObj = {
        //     name: data.name,
        //     mobile: data.mobile,
        //     email: data.email,
        //     countryCode: data.countryCode,
        //     currentLocation: {
        //         address: data.currentLocation[address] ? data.currentLocation[address] : "342" ,
        //     }
        // }
        try {
            if (id) {
                let URL = `${baseURL}/admin/updateDriver/${id}`
                const response = await axios.put(URL, reqObj, {
                    headers: {
                        // 'Content-Type': 'application/json',
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `${token} `
                    }
                })
                // console.log(response);
                Swal.fire({
                    icon: 'success',
                    title: 'Driver Info Updated Successfully',
                    showConfirmButton: false,
                    timer: 1500
                }).then(() => {
                    navigate(-1)
                })
            } else {
                let URL = `${baseURL}/admin/addDriver`
                const response = await axios.post(URL, reqObj, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `${token} `
                    }
                })
                Swal.fire({
                    icon: 'success',
                    title: response.data.responseMessage,
                    showConfirmButton: false,
                    timer: 1500
                }).then(() => {
                    navigate(-1)
                })
            }
        }
        catch (error) {
            console.error(error)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'something went wrong',
                showConfirmButton: false,
                timer: 1500
            });
        }

    }

    return (
        <Fragment>
            <Container fluid={true}>
                <div className="page-title">
                    <Row>
                        <Col xs='6'>
                            <H4> {id ? "Update Driver" : "Add Driver"}</H4>
                        </Col>
                        <Col xs="6">
                            <div className="text-right">
                                <Btn attrBtn={{ className: "btn-secondary-gradien", type: "button" }} navigateBack={true}>Back</Btn>
                            </div >
                        </Col >
                    </Row >
                </div >
            </Container >
            <Container fluid={true}>
                <Row>
                    <Col sm='12'>
                        <Card>
                            <CardBody className="">
                                <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <Col sm='6'>
                                            <FormGroup>
                                                <Label>Driver Name <span className="text-danger">*</span></Label>
                                                <input type="text" className="form-control" name="name" placeholder="Enter Driver Name" {...register('name', { required: true })} />
                                                <span style={{ color: 'red' }}>{errors.name && 'Driver name is required'}</span>
                                            </FormGroup>
                                        </Col>
                                        <Row className="mt-3">
                                            <Col sm='4'>
                                                <FormGroup>
                                                    <Label>
                                                        Country Code <span className="text-danger">*</span>
                                                    </Label>
                                                    <input
                                                        type="text"
                                                        name="countryCode"
                                                        className="form-control"
                                                        placeholder="Enter country code"
                                                        inputMode="numeric"
                                                        {...register('countryCode', {
                                                            required: 'country code is required',
                                                            // pattern: {
                                                            //     value: /^\d+$/,
                                                            //     message: 'Please enter only a number'
                                                            // }
                                                        })}
                                                    />
                                                    {errors.countryCode && <span style={{ color: 'red' }}>{errors.countryCode.message}</span>}
                                                </FormGroup>
                                            </Col>
                                            <Col sm='4'>
                                                <FormGroup>
                                                    <Label>
                                                        Mobile<span className="text-danger">*</span>
                                                    </Label>
                                                    <input
                                                        type="text"
                                                        name="mobile"
                                                        className="form-control"
                                                        placeholder="Enter mobile"
                                                        inputMode="numeric"
                                                        {...register('mobile', {
                                                            required: 'mobile number is required',
                                                            // pattern: {
                                                            //     value: /^\d+$/,
                                                            //     message: 'Please enter only a number'
                                                            // }
                                                        })}
                                                    />
                                                    {errors.mobile && <span style={{ color: 'red' }}>{errors.mobile.message}</span>}
                                                </FormGroup>
                                            </Col>
                                            <Col sm='4'>
                                                <FormGroup>
                                                    <Label>
                                                        Email<span className="text-danger">*</span>
                                                    </Label>
                                                    <input
                                                        type="text"
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Enter email"
                                                        // inputMode="numeric"
                                                        {...register('email', {
                                                            required: 'email is required',
                                                            // pattern: {
                                                            //     value: /^\d+$/,
                                                            //     message: 'Please enter only a number'
                                                            // }
                                                        })}
                                                    />
                                                    <span style={{ color: 'red' }}>{errors.email && errors.email.message}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {/* {(formData?.updatedAt && id) ? <p className="mt-2">
                                                Last updated at* {formData.updatedAt}
                                            </p> : ""} */}
                                            <div className="text-end" style={{ marginRight: "30px", marginTop: "25px" }}>
                                                <Btn attrBtn={{ className: 'me-3 btn-secondary-gradien', type: "submit" }}>
                                                    {id ? "Update" : "Add"}
                                                    {/* Add */}
                                                </Btn>
                                                {/* <Btn attrBtn={{ className: "btn-secondary-gradien", type: "button", onClick: reset }}>
                                                    Reset
                                                </Btn> */}
                                                {/* <Button className="btn-secondary-gradien" type="button" onClick={() => reset()}>
                                                    Reset
                                                </Button> */}
                                                <Button
                                                    className="btn-secondary-gradien"
                                                    type="button"
                                                    onClick={() => reset()}
                                                >
                                                    Reset
                                                </Button>

                                                {/* <Btn attrBtn={{ color: 'primary', className: 'me-3' }}>
                                                    {id ? "Update" : "Add"}
                                                </Btn>
                                                <Link to={`http://localhost:3000/fleet`}>
                                                    <Btn attrBtn={{ color: 'danger' }}>
                                                        Cancel
                                                    </Btn>
                                                </Link> */}

                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment >
    );

}

export default CreateDriver;