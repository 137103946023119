import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Label, Row } from "reactstrap";
// import { Country } from "../../../../Constant";
import * as Storage from '../../../../store/LocalStorage'
import { useForm, Controller } from "react-hook-form";
import { Btn, H4 } from "../../../../AbstractElements";
import axios from "axios";
import { baseURL } from "../../../../Services/baseURL";
import endPoints from "../../../../Services/EndPoints";
import Swal from "sweetalert2";
// import countries from "..../../../Pages/countries+states+cities
// import countries from '../../../Pages/countries+states+cities'


const NewTax = () => {
    const { id } = useParams()

    const { register, handleSubmit, formState: { errors }, setValue, reset, control } = useForm()
    const { navigate, history } = useNavigate();
    const [formData, setFormData] = useState(null)
    const token = JSON.parse(Storage.get('token'))
    // const [selectedCountry, setSelectedCountry] = useState('');
    // const [selectedState, setSelectedState] = useState('');
    // const [selectedCity, setSelectedCity] = useState('');

    // const countries = [
    //     { id: 1, name: 'USA', states: [{ id: 1, name: 'California', cities: ['Los Angeles', 'San Francisco'] }] },
    //     { id: 2, name: 'UK', states: [{ id: 2, name: 'England', cities: ['London', 'Manchester'] }] }
    //     // Add more countries, states, and cities as needed
    // ];

    const fetchTaxDataById = async () => {
        try {
            const URL = `${baseURL}${endPoints.getTax}${id}`
            const response = await axios.get(URL, {
                headers: {
                    'Authorization': `${token}`
                }
            })
            if (response?.status === 200 && response) {
                setFormData(response.data.data)
            }
        }
        catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        if (id) {
            if (formData && setValue) {
                Object.keys(formData).forEach(key => {
                    if (key in formData) {
                        setValue(key, formData[key])
                    }
                });
            }
        }
    }, [formData, setValue])

    useEffect(() => {
        if (id) {
            fetchTaxDataById();
        }
    }, [])

    const onSubmit = async (data) => {
        // console.log(data)
        const requestObj = {
            fedralIncomeTax: data.fedralIncomeTax,
            stateIncomeTax: data.stateIncomeTax,
            city: data.city,
            state: data.state,
            country: data.country
        }
        try {
            if (id) {
                const URL = `${baseURL}${endPoints.updateTax}${id}`

                await axios.put(URL, requestObj,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `${token}`
                        }
                    })
                Swal.fire({
                    icon: 'success',
                    title: 'Tax Rate Updated Successfully',
                    showConfirmButton: false,
                    timer: 1500
                })
                // navigate(-1)
                // .then(() => {
                //     // navigate.push(-1)
                //     // window.location.href = '/allTaxes';
                //     // history.push('/allTaxes');
                // })
                // console.log(response)
            } else {
                // try {
                // const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/tax`, {
                //     method: id? 'PUT' : 'POST',
                //     headers: {
                //         'Content-Type': 'application/json',
                //         'Authorization': `Bearer ${token}`
                //     },
                //     body: JSON.stringify(data)
                // })
                // let requestObj = {
                //     fedralIncomeTax: data.fedralIncomeTax,
                //     stateIncomeTax: data.stateIncomeTax,
                //     city: data.city,
                //     state: data.state,
                //     country: data.country
                // }
                // {
                //     "fedralIncomeTax": 1.75,
                //     "stateIncomeTax": 8.83,
                //     "city": "mohali",
                //     "state": "punjab",
                //     "country": "pakistan"
                // }

                // let reqData = JSON.stringify(requestObj);

                // console.log(data, "data", "requestobj", requestObj);
                const URL = `${baseURL}${endPoints.addTax}`
                await axios.post(URL, requestObj, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `${token}`
                    }
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Tax Rate Added Successfully',
                    showConfirmButton: false,
                    timer: 1500
                })
                // .then(() => {
                //     navigate.push(-1)
                //     // navigate("allTaxes")
                // })
                // console.log(response)
                // fetch(`${process.env.REACT_APP_API_URL}/api/v1/tax`, {
                //     method: id ? 'PUT' : 'POST',
                //     headers: {
                //         'Content-Type': 'application/json',
                //         'Authorization': `Bearer ${token}`
                //     },
                //     body: JSON.stringify(data)
                // })
                // const responseData = await response.json()
                // console.log(responseData)
                // if (response.status === 200) {
                //     reset()
                //     window.location.reload()
                // }
                // }

            }
        }
        catch (e) {
            console.error(e);
            Swal.fire({
                icon: 'error',
                title: 'Something went wrong',
                text: `${e?.response?.data?.responseMessage}`,
                showConfirmButton: true,
                // timer: 1600
            })
        }
    }

    return (
        <Fragment>
            <Container fluid={true}>
                <div className="page-title">
                    <Row>
                        <Col xs='6'>
                            <H4>{id ? "Edit Tax Rate" : "Add New Tax Rate"}</H4>
                        </Col>
                        <Col xs="6">
                            <div className="text-right">
                                <Btn attrBtn={{ className: "btn-secondary-gradien", type: "button" }} navigateBack={true}>Back</Btn>
                                {/* <Link to={`http://localhost:3000/allTaxes`}>
                                    <Btn attrBtn={{ color: 'primary' }}>Back</Btn>
                                </Link > */}
                            </div >
                        </Col >
                    </Row >
                </div >
            </Container >
            <Container fluid={true}>
                <Row>
                    <Col sm='12'>
                        <Card>
                            <CardBody className="">
                                <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <Col sm='4'>
                                            <FormGroup>
                                                <Label>
                                                    Federal Tax<span className="text-danger">*</span>
                                                </Label>
                                                <input
                                                    type="text"
                                                    name="fedralIncomeTax" // or amount
                                                    className="form-control"
                                                    placeholder="Enter federal tax"
                                                    inputMode="numeric"
                                                    {...register('fedralIncomeTax', {
                                                        required: 'fedral tax is required',
                                                    })}
                                                />
                                                {errors.fedralIncomeTax && <span style={{ color: 'red' }}>{errors.fedralIncomeTax.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col sm='4'>
                                            <FormGroup>
                                                <Label>
                                                    State Tax<span className="text-danger">*</span>
                                                </Label>
                                                <input
                                                    type="text"
                                                    name="stateIncomeTax" // or amount
                                                    className="form-control"
                                                    placeholder="Enter state tax"
                                                    inputMode="numeric"
                                                    {...register('stateIncomeTax', {
                                                        required: 'state tax is required',
                                                    })}
                                                />
                                                {errors.stateIncomeTax && <span style={{ color: 'red' }}>{errors.stateIncomeTax.message}</span>}
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row className="mt-3">
                                        <Col sm='4'>
                                            <FormGroup>
                                                <Label htmlFor="city">City Name <span className="text-danger">*</span></Label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="city"
                                                    name="city"
                                                    placeholder="Enter City Name"
                                                    {...register('city',
                                                        { required: true })} />
                                                <span style={{ color: 'red' }}>{errors.city && 'city name is required'}</span>
                                            </FormGroup>
                                        </Col>
                                        <Col sm='4'>
                                            <FormGroup>
                                                <Label htmlFor="state">State Name <span className="text-danger">*</span></Label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="state"
                                                    name="state"
                                                    placeholder="Enter State Name"
                                                    {...register('state',
                                                        { required: true })} />
                                                <span style={{ color: 'red' }}>{errors.state && 'state name is required'}</span>
                                            </FormGroup>
                                        </Col>
                                        <Col sm='4'>
                                            <FormGroup>
                                                <Label htmlFor="country">Country Name <span className="text-danger">*</span></Label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="country"
                                                    name="country"
                                                    placeholder="Enter Country Name"
                                                    {...register('country',
                                                        { required: true })} />
                                                <span style={{ color: 'red' }}>{errors.country && 'country name is required'}</span>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    {/* <Row className="mt-3">
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label for="country" style={{ marginRight: '0.5rem' }}>Country<span className="text-danger">*</span></Label>
                                                <Controller
                                                    name="country"
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <select
                                                            id="country"
                                                            {...field}
                                                            onChange={(e) => {
                                                                field.onChange(e);
                                                                setSelectedCountry(e.target.value);
                                                            }}
                                                        >
                                                            <option value="">Select Country</option>
                                                            {countries.map((country) => (
                                                                <option key={country.id} value={country.name}>{country.name}</option>
                                                            ))}
                                                        </select>
                                                    )}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Label for="state" style={{ marginRight: '0.5rem' }}>State<span className="text-danger">*</span></Label>
                                                {selectedCountry && (
                                                    <Controller
                                                        name="state"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) => (
                                                            <select
                                                                id="state"
                                                                {...field}
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    setSelectedState(e.target.value);
                                                                }}
                                                            >
                                                                <option value="">Select State</option>
                                                                {countries.find(country => country.name === selectedCountry)?.states.map((state) => (
                                                                    <option key={state.id} value={state.name}>{state.name}</option>
                                                                ))}
                                                            </select>
                                                        )}
                                                    />
                                                )}
                                            </FormGroup>
                                        </Col>
                                        {/* <Col sm="4">
                                            <FormGroup>
                                                <Label for="city" style={{ marginRight: '0.5rem' }}>City<span className="text-danger">*</span></Label>
                                                <Controller
                                                    name="city"
                                                    // ref={register({ required: 'City is required' })}
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: selectedState ? true : false }} // Apply required rule only if state is selected
                                                    render={({ field }) => (
                                                        <select
                                                            id="city"
                                                            {...field}
                                                            onChange={(e) => {
                                                                field.onChange(e);
                                                                setSelectedCity(e.target.value);
                                                            }}
                                                        >
                                                            <option value="">Select City</option>
                                                            {selectedState &&
                                                                countries.find((country) => country.name === selectedCountry)?.states
                                                                    .find((state) => state.name === selectedState)?.cities.map(
                                                                        (city, index) => (
                                                                            <option key={index} value={city}>
                                                                                {city}
                                                                            </option>
                                                                        )
                                                                    )}
                                                        </select>
                                                    )}
                                                />
                                                {/* {selectedState && (
                                                    <Controller
                                                        name="city"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) => (
                                                            <select
                                                                id="city"
                                                                {...field}
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    setSelectedCity(e.target.value);
                                                                }}
                                                            >
                                                                <option value="">Select City</option>
                                                                {countries.find(country => country.name === selectedCountry)?.states
                                                                    .find(state => state.name === selectedState)?.cities.map((city, index) => (
                                                                        <option key={index} value={city}>{city}</option>
                                                                    ))}
                                                            </select>
                                                        )}
                                                    />
                                                )} */}
                                    {/* </FormGroup>
                                        </Col> */}
                                    {/* </Row> */}



                                    <Row>
                                        <Col>
                                            <div className="text-end">
                                                <Btn attrBtn={{ className: "btn-secondary-gradien me-3", type: "submit" }} navigateBack={true}>
                                                    {id ? "Update" : "Add"}
                                                </Btn>

                                                {/* <Btn attrBtn={{ color: 'primary', className: 'me-3' }} type="submit"> */}
                                                {/* {id ? "Update" : "Add"} */}
                                                {/* </Btn> */}
                                                {/* {!id ?  */}
                                                <Button className="btn-secondary-gradien" type="button" onClick={() => reset()}>
                                                    Reset
                                                </Button>
                                                {/* //  : ""} */}
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment >
    );





    // return (
    //     <Fragment>
    //         <Container fluid={true}>
    //             <div className="page-title-box">
    //                 <div className="row align-items-center">
    //                     <div className="col-sm-6">
    //                         <h4 className="page-title">New Tax</h4>
    //                     </div>
    //                     <div className="col-sm-6">
    //                         <ol className="breadcrumb float-right">
    //                             <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
    //                             <li className="breadcrumb-item active">New Tax</li>
    //                         </ol>
    //                     </div>
    //                 </div>
    //                 {/* end row */}
    //             </div>
    //             {/* end page-title */}
    //         </Container>
    //     </Fragment>
    // );
}

export default NewTax;