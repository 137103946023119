import { Fragment } from "react";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import TaxesTable from "./DataTableComponent";


const ManageTaxes = () => {
    return (
        <Fragment>
            <Container fluid={true} style={{ padding: '25px', minHeight: '150px' }}>
                <Row>
                    <Col md="12">
                        <Card>
                            <CardBody>
                                <TaxesTable />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default ManageTaxes;