import { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { PlusCircle, MoreVertical, Edit, Trash2 } from "react-feather";
import { Button, Col, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { useNavigate } from "react-router-dom"
import * as Storage from "../../../store/LocalStorage"
import { useDataContext } from "../../../Context/hooks/useDataContext";
import { baseURL } from "../../../Services/baseURL";
import endPoints from "../../../Services/EndPoints";
import axios from "axios";
import { couponColumns } from "../../DataTableCols";
import Swal from "sweetalert2";
import LogoutModal from "../reuseModal";

const CouponsTable = () => {
    const { couponsData, setCouponsData, isModalOpen, toggleModal } = useDataContext()

    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);

    const navigate = useNavigate()
    const token = JSON.parse(Storage.get("token"))

    const fetchAllCoupons = async () => {
        try {
            setLoading(true)
            let params = {
                skip: (currentPage - 1) * perPage,
                limit: 1000,
                // perPage,
            }
            const URL = `${baseURL}${endPoints.getAllCoupons}`
            const response = await axios.get(URL, {
                headers: {
                    'Authorization': `${token}`
                },
                params: params
            })
            if (response?.status === 200) {
                setCouponsData(response?.data?.data)
                setTotalRows(response.data.totalRecord)
                setLoading(false)
            }
        }
        catch (error) {
            console.error(error)
            if (error.response.status === 403) {
                toggleModal(true)
            }
        }
    }

    useEffect(() => {
        fetchAllCoupons();
    }, [currentPage, perPage]);

    const handlePageChange = (currentPage) => {
        setCurrentPage(currentPage);
    };

    const handlePerRowsChange = async (perPage, currentPage) => {
        setPerPage(perPage);
        setCurrentPage(currentPage)
    };


    const handleAddBtn = () => {
        navigate("/coupons/add")
    }

    const handleEdit = (id) => {
        navigate(`/coupons/edit/${id}`)
    }

    const handleDelete = async (id) => {
        const confirmed = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this action!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });

        if (confirmed.isConfirmed) {
            try {
                await axios.delete(`${baseURL}${endPoints.deleteCoupon}/${id}`, {
                    headers: {
                        Authorization: `${token}`,
                    }
                });
                Swal.fire({
                    icon: 'success',
                    title: 'Deleted!',
                    text: "Coupon deleted successfully",
                    showConfirmButton: false,
                    timer: 1600
                });
                fetchAllCoupons()
            }
            catch (error) {
                console.error("Error deleting coupon:", error)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: "Something went wrong",
                    showConfirmButton: false,
                    timer: 1600
                });
            }
        }
    }

    const subHeaderContent = (
        <div>
            <input type="text" placeholder="Search" />
            <select >
                <option value="">Select</option>
                <option value="filter1">date</option>
                <option value="filter2">discount</option>
            </select>
            <button>Apply</button>
        </div>
    );

    const renderExpandableComponent = ({ data }) => {
        const couponDescription = data.description ? data.description : "No description available about this coupon."
        return (
            <div style={{ margin: "20px" }}>
                <p style={{ marginLeft: '50px' }}>
                    <span style={{ fontWeight: 'bold' }}>Description:{" "}</span>
                    {couponDescription}
                </p>
            </div>
        );
    };

    const actionColumn = [
        {
            name: 'Action',
            selector: row => row.action,
            cell: (row) => (
                <div className="d-flex">
                    <div className="cursor-pointer">
                        <UncontrolledDropdown className='action_dropdown'>
                            <DropdownToggle className='action_btn'
                            >
                                <MoreVertical color='#000' size={16} />
                            </DropdownToggle>
                            <DropdownMenu style={{ backgroundColor: "#7366ff" }}>
                                <DropdownItem className='d-flex align-items-center justify-between block px-4 py-2 text-sm text-black-700 hover:bg-gray-100 w-full' onClick={() => handleEdit(row?._id)}>
                                    Edit
                                    <Edit className="w-5 h-5 " />
                                </DropdownItem>
                                <DropdownItem className='d-flex align-items-center justify-between delete_item block px-4 py-2 text-sm text-black-700 hover:bg-gray-100 w-full' onClick={() => handleDelete(row?._id)}>
                                    Delete
                                    <Trash2 className="w-5 h-5 " />
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </div>
            ),
            center: true,
            sortable: false,
            // width: 'auto',
            // maxWidth: 'none',
            // minWidth: '50px',
            // grow: 1,
            // conditionalCellStyles: [
            //     {
            //         when: cell => cell && cell.value,
            //         style: cell => ({
            //             width: `${cell.value.length * 10}px`
            //         })
            //     }
            // ]

        }
    ]

    const allColumns = couponColumns.concat(actionColumn)

    return (
        <Fragment>
            <Row xxl={12} className="pb-4">
                <Row>
                    <Col md={12}>
                        <div className="flex justify-between">
                            <h2>Manage Coupons</h2>
                            {
                                <Button className='btn btn-secondary-gradien d-flex flex-end align-items-center ms-3'
                                    onClick={handleAddBtn} >
                                    <PlusCircle className="w-6 h-6 mr-2" />
                                    Add New Coupon
                                </Button>
                            }
                        </div>
                    </Col>
                </Row>
            </Row>
            {couponsData && couponsData.length !== 0 ? (
                <DataTable
                    data={couponsData || []}
                    columns={allColumns}
                    center={true}
                    expandOnRowClicked
                    expandableRows
                    expandableRowsComponent={renderExpandableComponent}
                    expandableRowsHideExpander
                    highlightOnHover
                    striped
                    // subHeader
                    responsive
                    dense
                    // subHeaderAlign="right"
                    // subHeaderWrap
                    // subHeaderComponent={subHeaderContent}
                    pagination
                    paginationServer
                    progressPending={loading}
                    paginationTotalRows={totalRows}
                    // paginationPerPage={5}
                    // paginationPosition="top"
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                />
            ) : (
                <Fragment>
                    <Row style={{ minHeight: "250px" }}>
                        <Col md={12} className="flex justify-center">
                            <div className="text-center p-5">
                                <h1 className="text-muted">No data Available</h1>
                            </div>
                        </Col>
                    </Row>
                </Fragment>
            )}
            {isModalOpen && <LogoutModal />}
        </Fragment>
    );
}

export default CouponsTable;