import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FileText, LogIn, Mail, User } from "react-feather";
import man from "../../../assets/images/dashboard/profile.png";
import man2 from "../../../assets/images/dashboard/Ellipse 11.png"

import { LI, UL, Image, P } from "../../../AbstractElements";
import CustomizerContext from "../../../_helper/Customizer";
import { Account, Admin, Inbox, LogOut, Taskboard } from "../../../Constant";
import { useAuthContext } from "../../../Context/Auth";
import { toast } from "react-toastify";
import { baseURL } from "../../../Services/baseURL";
import axios from "axios";

const UserHeader = () => {
  const history = useNavigate();
  const { logout, user, authenticated } = useAuthContext()


  const [profile, setProfile] = useState("");
  const [name, setName] = useState("");
  const { layoutURL } = useContext(CustomizerContext);
  // const authenticated = JSON.parse(localStorage.getItem("authenticated"));
  const loginTrue = JSON.parse(localStorage.getItem("login"));

  const profileData = loginTrue ? JSON.parse(localStorage.getItem("profileData")) : "";
  const loggedUserData = user ? user : profileData;
  const token = JSON.parse(localStorage.getItem("token"));
  // useEffect(() => {
  //   userData()
  // }, []);

  // const userData = async () => {
  //   setProfile(await JSON.parse(localStorage.getItem("UserData")));
  //   // const authenticated = await JSON.parse(localStorage.getItem("authenticated"));
  //   setName(localStorage.getItem("Name"))
  //   // setName(profile.name ? profile.name : profile.email);
  // }
  // console.log(name, profile.email, profile.userType, name, authenticated, loginTrue)

  const Logout = async () => {

    // try {
    //   const URL = `${baseURL}/admin/logout`
    //   const response = await axios.post(URL, {
    //     headers: {
    //       'Authorization': `${token} `
    //     }
    //   })
    //   console.log(response, response.data)
    // localStorage.removeItem("profileURL");
    // localStorage.removeItem("token");
    // localStorage.removeItem("auth0_profile");
    // localStorage.removeItem("Name");
    // localStorage.removeItem("UserData");
    // localStorage.setItem("authenticated", false);
    localStorage.setItem("login", false);
    localStorage.removeItem("role_name");
    localStorage.removeItem("isActive");
    logout();
    history(`/login`);
    toast.success("Logout Successfull")
    // }
    // catch (error) {
    //   console.log(error, "err")
    // }
  };

  const UserMenuRedirect = (redirect) => {
    history(redirect);
  };

  return (
    <li className="profile-nav onhover-dropdown pe-0 py-0">
      <div className="media profile-media">
        {/* <Image
          attrImage={{
            className: "b-r-10 m-0",
            src: `${loggedUserData.profilePic ? loggedUserData.profilePic : man2}`,
            alt: "",
          }}
        /> */}
        <div className="media-body">
          <span className="min-w-lg">{loggedUserData.name ? loggedUserData.name : loggedUserData.email}</span>
          {/* <h6 className="min-w-lg">{loggedUserData.name ? loggedUserData.name : loggedUserData.email}</h6> */}
          <P attrPara={{ className: "mb-0 font-roboto" }}>
            {loggedUserData.userType ? loggedUserData.userType.charAt(0).toUpperCase() + loggedUserData.userType.slice(1) : "Admin"} <i className="middle fa fa-angle-down"></i>
          </P>
        </div>
      </div>
      <UL attrUL={{ className: "simple-list profile-dropdown onhover-show-div" }}>
        <LI
          attrLI={{
            onClick: () => UserMenuRedirect(`/myProfile`),
          }}>
          <User />
          <span>{Account} </span>
        </LI>
        {/* <LI
          attrLI={{
            onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/email-app/${layoutURL}`),
          }}>
          <Mail />
          <span>{Inbox}</span>
        </LI>
        <LI
          attrLI={{
            onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/todo-app/todo/${layoutURL}`),
          }}>
          <FileText />
          <span>{Taskboard}</span>
        </LI> */}
        <LI attrLI={{ onClick: Logout }}>
          <LogIn />
          <span>{LogOut}</span>
        </LI>
      </UL>
    </li>
  );
};

export default UserHeader;
