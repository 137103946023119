import { Fragment, useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Label, Row } from "reactstrap";
import { Btn, H4 } from "../../../../AbstractElements";
import { useNavigate, Link, useParams } from 'react-router-dom'
import * as Storage from "../../../../store/LocalStorage";
import { baseURL } from "../../../../Services/baseURL";
import endPoints from "../../../../Services/EndPoints";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { type } from "world-map-geojson";


const EditService = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const {
        register,
        handleSubmit,
        formState: { errors }, trigger, validate,
        getValues,
        reset,
        control,
        setValue,
    } = useForm()

    const [formData, setFormData] = useState(null)

    const token = JSON.parse(Storage.get('token'))

    const fetchServiceDataById = async () => {
        try {
            const URL = `${baseURL}${endPoints.getServiceById}${id}`
            const response = await axios.get(URL, {
                headers: {
                    'Authorization': `${token}`,
                }
            })
            if (response?.status === 200 && response) {
                setFormData(response.data.data)
            }
            else {
                console.error('Failed to fetch data:', response.statusText);
            }
        }
        catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        if (id) {
            fetchServiceDataById();
        }
    }, [])

    useEffect(() => {
        if (id) {
            if (formData && setValue) {
                const { baseFare, chargesPerMile, chargesPerMinute,commissionCharge, description, name, type } = formData

                setValue("baseFare", baseFare)
                setValue("chargesPerMile", chargesPerMile)
                setValue("chargesPerMinute", chargesPerMinute)
                setValue("commissionCharge", commissionCharge)
                setValue("description", description)
                if (name) {
                    setValue("type", name)
                } else {
                    setValue("type", type)
                }

                // Object.keys(formData).forEach(key => {
                //     if (key in formData) {
                //         setValue(key, formData[key])
                //     }
                //     if (key === 'name') {
                //         const type = formData[key]
                //         setValue('type', type);
                //     }
                // })
            }
        }
    }, [formData, setValue])

    const onSubmit = async (data) => {
        // console.log(data)
        try {
            const requestObj = {
                baseFare: data.baseFare,
                chargesPerMile: data.chargesPerMile,
                chargesPerMinute: data.chargesPerMinute,
                commissionCharge: data.commissionCharge,
                description: data.description,
                // type: data.type,
                // cell: row => row.type ? row.type.charAt(0).toUpperCase() + row.type.slice(1) : row.name.charAt(0).toUpperCase() + row.name.slice(1),

                name: data.type.charAt(0).toUpperCase() + data.type.slice(1)
            }

            if (id) {
                const URL = `${baseURL}${endPoints.updateService}${id}`
                await axios.put(URL, requestObj,
                    {
                        headers: {
                            'Authorization': `${token}`,
                        }
                    })
                Swal.fire({
                    icon: 'success',
                    title: 'Service Updated Successfully',
                    showConfirmButton: false,
                    timer: 1500
                }).then(() => {
                    navigate(-1);
                });
            } else {
                const URL = `${baseURL}${endPoints.addService}`
                await axios.post(URL,
                    requestObj,
                    {
                        headers: {
                            'Authorization': `${token}`,
                        }
                    })
                Swal.fire({
                    icon: 'success',
                    title: 'Service Added Successfully',
                    showConfirmButton: false,
                    timer: 1500
                }).then(() => {
                    navigate(-1);
                })
            }
        }
        catch (e) {
            console.error(e);
            Swal.fire({
                icon: 'error',
                title: 'Something went wrong',
                showConfirmButton: false,
                timer: 1500
            })
        }
    }

    return (
        <Fragment>
            <Container fluid={true}>
                <div className="page-title">
                    <Row>
                        <Col xs='6'>
                            <H4>{id ? "Edit Service" : "Add New Service"}</H4>
                        </Col>
                        <Col xs="6">
                            <div className="text-right">
                                <Btn attrBtn={{ className: "btn-secondary-gradien", type: "button" }} navigateBack={true}>Back</Btn>
                            </div >
                        </Col >
                    </Row >
                </div >
            </Container >
            <Container fluid={true}>
                <Row>
                    <Col sm='12'>
                        <Card>
                            <CardBody className="">
                                <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <Row>
                                            <Col sm='6'>
                                                <FormGroup>
                                                    <Label>Service Name <span className="text-danger">*</span></Label>
                                                    <input type="text" className="form-control" name="type" placeholder="Enter Service Name" {...register('type', { required: true })} />
                                                    <span style={{ color: 'red' }}>{errors.type && 'Service type is required'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm='6'>
                                                <FormGroup>
                                                    <Label>Service Description <span className="text-danger">*</span></Label>
                                                    <input type="text" className="form-control" name="description" placeholder="Enter Description" {...register('description', { required: true })} />
                                                    <span style={{ color: 'red' }}>{errors.description && 'Service description is required'}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col sm='4'>
                                                <FormGroup>
                                                    <Label>
                                                        Commission<span className="text-danger">*</span>
                                                    </Label>
                                                    <input
                                                        type="text"
                                                        name="commissionCharge"
                                                        className="form-control"
                                                        placeholder="Enter commission charge"
                                                        inputMode="numeric"
                                                        {...register('commissionCharge', {
                                                            required: 'commission charge is required',
                                                            // pattern: {
                                                            //     value: /^\d+$/,
                                                            //     message: 'Please enter only a number'
                                                            // }
                                                        })}
                                                    />
                                                    {errors.commissionCharge && <span style={{ color: 'red' }}>{errors.commissionCharge.message}</span>}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col sm='4'>
                                                <FormGroup>
                                                    <Label>
                                                        Base Fare <span className="text-danger">*</span>
                                                    </Label>
                                                    <input
                                                        type="text"
                                                        name="baseFare"
                                                        className="form-control"
                                                        placeholder="Enter Base Fare"
                                                        inputMode="numeric"
                                                        {...register('baseFare', {
                                                            required: 'Base Fare is required',
                                                            // pattern: {
                                                            //     value: /^\d+$/,
                                                            //     message: 'Please enter only a number'
                                                            // }
                                                        })}
                                                    />
                                                    {errors.baseFare && <span style={{ color: 'red' }}>{errors.baseFare.message}</span>}
                                                </FormGroup>
                                            </Col>
                                            <Col sm='4'>
                                                <FormGroup>
                                                    <Label>
                                                        Minute Charges<span className="text-danger">*</span>
                                                    </Label>
                                                    <input
                                                        type="text"
                                                        name="chargesPerMinute"
                                                        className="form-control"
                                                        placeholder="Enter charges per min"
                                                        inputMode="numeric"
                                                        {...register('chargesPerMinute', {
                                                            required: 'Minute Charges is required',
                                                            // pattern: {
                                                            //     value: /^\d+$/,
                                                            //     message: 'Please enter only a number'
                                                            // }
                                                        })}
                                                    />
                                                    {errors.chargesPerMinute && <span style={{ color: 'red' }}>{errors.chargesPerMinute.message}</span>}
                                                </FormGroup>
                                            </Col>
                                            <Col sm='4'>
                                                <FormGroup>
                                                    <Label>
                                                        Distance Charges (per mile)<span className="text-danger">*</span>
                                                    </Label>
                                                    <input
                                                        type="text"
                                                        name="chargesPerMile"
                                                        className="form-control"
                                                        placeholder="Enter charges per mile"
                                                        inputMode="numeric"
                                                        {...register('chargesPerMile', {
                                                            required: 'Distance Charges is required',
                                                            // pattern: {
                                                            //     value: /^\d+$/,
                                                            //     message: 'Please enter only a number'
                                                            // }
                                                        })}
                                                    />
                                                    <span style={{ color: 'red' }}>{errors.chargesPerMile && errors.chargesPerMile.message}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {(formData?.updatedAt && id) ? <p className="mt-2">
                                                Last updated at* {formData.updatedAt}
                                            </p> : ""}
                                            <div className="text-end" style={{ marginRight: "30px", marginTop: "25px" }}>
                                                <Btn attrBtn={{ className: 'me-3 btn-secondary-gradien', type: "submit" }}>
                                                    {id ? "Update" : "Add"}
                                                </Btn>
                                                {/* <Btn attrBtn={{ className: "btn-secondary-gradien", type: "button", onClick: reset }}>
                                                    Reset
                                                </Btn> */}
                                                <Button className="btn-secondary-gradien" type="button" onClick={() => reset()}>
                                                    Reset
                                                </Button>

                                                {/* <Btn attrBtn={{ color: 'primary', className: 'me-3' }}>
                                                    {id ? "Update" : "Add"}
                                                </Btn>
                                                <Link to={`http://localhost:3000/fleet`}>
                                                    <Btn attrBtn={{ color: 'danger' }}>
                                                        Cancel
                                                    </Btn>
                                                </Link> */}

                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment >
    );
}

export default EditService;