import React, { Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';
import { routes } from './Routes';
import AppLayout from '../Layout/Layout';

const LayoutRoutes = () => {
  const userRole = JSON.parse(localStorage.getItem("role_name"));
  return (
    <>
      <Routes>
        {routes.map(({ path, Component }, i) => (
          // console.log(userRole)
          (userRole !== "admin") && /partner/i.test(path.toLowerCase())
            ? null :
            <Fragment key={i}>
              <Route element={<AppLayout />} key={i}>
                <Route path={path} element={Component} />
              </Route>
            </Fragment>
        ))}
      </Routes>
    </>
  );
};

export default LayoutRoutes;