import { Fragment, useEffect, useState } from "react";
import { Row, Card, Col, Button, Container, CardBody, Form, FormGroup, Label, Input } from "reactstrap";
import { Breadcrumbs, Btn } from "../../../AbstractElements";
import { baseURL } from "../../../Services/baseURL";
import endPoints from "../../../Services/EndPoints";
import * as Storage from "../../../store/LocalStorage";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom'
import { useDataContext } from "../../../Context/hooks/useDataContext";
import { toast } from "react-toastify";
import { useAuthContext } from "../../../Context/Auth";
import Swal from "sweetalert2";
import LogoutModal from "../reuseModal";

const ManageProfile = () => {

    const { user, updateUserContext } = useAuthContext();
    const { isModalOpen, toggleModal } = useDataContext()
    const loggedUserData = user ? user : ""
    const [editState, setEditState] = useState(false)
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm()
    const userData = JSON.parse(Storage.get("role_name"))
    const token = JSON.parse(Storage.get("token"))

    console.log(userData, " userdaata")
    const onSubmit = async (data) => {
        // console.log(data, "senddata")
        const requestObj = {
            name: data.name,
            email: data.email,
            phone: data.phone,
            profilePic: data.profilePic,
            // profilePic: "profile/9a4cb795-a0c9-4d9f-8a86-0fbda06ed389.webp",
            dob: data.dob,
            emergencyContact: data.emergencyContact,
            gender: data.gender,
            mobile: data.mobile,
        }

        try {
            const endpoint = (userData === "admin") ? endPoints.updateAdminProfile : endPoints.updatePartnerProfile
            const URL = `${baseURL}${endpoint}`
            const response = await axios.put(URL, requestObj, {
                headers: {
                    "Authorization": `${token}`
                }
            })
            Storage.set("profileData", JSON.stringify(response?.data?.data))
            Swal.fire({
                icon: 'success',
                title: response.data.responseMessage,
                showConfirmButton: false,
                timer: 1600
            })
            // .then(() => {

            // })
            // console.log(response)
            // if (response.status === 200) {
            //     toast.success(`${response.data.responseMessage}`)
            //     console.log(response)
            //     updateUserContext(response.data.data)
            //     // reset()
            //     // window.location.reload()
            // }
        } catch (e) {
            console.error(e)
            Swal.fire({
                icon: 'error',
                title: e.response.data.responseMessage,
                showConfirmButton: true,
                // timer: 1600
            })
        }
    }

    const fetchProfileData = async () => {
        const endpoint =
            // ("admin" === "admin") 
            (userData === "admin") ? endPoints.getAdminProfile : endPoints.getPartnerProfile
        try {
            const URL = `${baseURL}${endpoint}`
            const response = await axios.get(URL, {
                headers: {
                    'Authorization': `${token}`
                }
            })
            // console.log(response)
            if (response) {
                const resData = await response?.data?.data
                updateUserContext(resData)
            }
            else {
                console.error('Failed to fetch logged user data', response.statusText)
            }
        }
        catch (error) {
            console.error(error)
            if (error.response.status === 403) {
                toggleModal(true)
            }
        }
    }

    useEffect(() => {
        const { name, countryCode, mobile, profilePic, emergencyContact, gender, dob, email } = loggedUserData
        setValue("name", name)
        setValue("countryCode", countryCode)
        setValue("profilePic", profilePic)
        setValue("emergencyContact", emergencyContact)
        setValue("gender", gender)
        setValue("mobile", mobile)
        setValue("email", email)
        if (dob) {
            const date = new Date(dob);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();

            const formattedDate = `${day}${month}${year}`;
            setValue('dob', formattedDate);
        }
    }, [loggedUserData, setValue])

    // useEffect(() => {
    //     if (loggedUserData && setValue) {
    //         Object.keys(loggedUserData).forEach(key => {
    //             console.log(key, loggedUserData[key])
    //             if (key in loggedUserData) {
    //                 if (key === 'dob') {
    //                     const date = new Date(loggedUserData[key]);
    //                     const day = String(date.getDate()).padStart(2, '0');
    //                     const month = String(date.getMonth() + 1).padStart(2, '0');
    //                     const year = date.getFullYear();

    //                     const formattedDate = `${day}/${month}/${year}`;
    //                     setValue(key, formattedDate);
    //                 } else {
    //                     setValue(key, loggedUserData[key])
    //                 }
    //             }
    //         })
    //     }
    // }, [loggedUserData, setValue])

    useEffect(() => {
        fetchProfileData();
    }, [])

    // useEffect(() => {
    //     console.log("edir", editState)
    // }, [editState])


    return (
        <Fragment>
            <Breadcrumbs mainTitle="MyProfile" />
            <Container fluid={true}>
                <Row>
                    <Col sm={12}>
                        <Card>
                            <CardBody>
                                {/* <div className="text-end">
                                    {editState ?
                                        (
                                            // <Btn attrBtn={{ color: 'danger', type:'button' }} type="button" onClick={() => setEditState(false)}>
                                            //     Cancel
                                            // </Btn>
                                            <>
                                                <Btn attrBtn={{ className: 'me-3 btn-secondary-gradien', type: "submit" }}>
                                                    Update
                                                </Btn>
                                                <Button color="danger" type="button" onClick={() => setEditState(false)}>
                                                    Cancel
                                                </Button>
                                            </>

                                        ) : (
                                            // <Btn attrBtn={{ color: 'primary' }} type="button" onClick={() => setEditState(true)}>
                                            //     Edit
                                            // </Btn>
                                            // <button style={{ color: 'primary' }} type="button" onClick={() => setEditState(true)}>Edit</button>
                                            <Button color="primary" type="button" onClick={() => setEditState(true)}>
                                                Edit
                                            </Button>
                                        )}
                                </div> */}
                                <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <div className="text-end">
                                            {editState ?
                                                (
                                                    // <Btn attrBtn={{ color: 'danger', type:'button' }} type="button" onClick={() => setEditState(false)}>
                                                    //     Cancel
                                                    // </Btn>
                                                    <>
                                                        <Btn attrBtn={{ className: 'me-3 btn-secondary-gradien', type: "submit" }}>
                                                            Update
                                                        </Btn>
                                                        <Button color="danger" type="button" onClick={() => setEditState(false)}>
                                                            Cancel
                                                        </Button>
                                                    </>

                                                ) : (
                                                    // <Btn attrBtn={{ color: 'primary' }} type="button" onClick={() => setEditState(true)}>
                                                    //     Edit
                                                    // </Btn>
                                                    // <button style={{ color: 'primary' }} type="button" onClick={() => setEditState(true)}>Edit</button>
                                                    <Button className={'me-3 btn-secondary-gradien'} color="primary" type="button" onClick={() => setEditState(true)}>
                                                        Edit
                                                    </Button>
                                                )}
                                        </div>
                                        {/* <Row>
                                            <Col>
                                                {/* <div className="text-end">
                                                    {editState ?
                                                        (
                                                            <Btn attrBtn={{ color: 'danger' }} type="button" onClick={() => setEditState(true)}>
                                                                Cancel
                                                            </Btn>
                                                        ) : (
                                                            <Btn attrBtn={{ color: 'primary' }} type="button" onClick={() => setEditState(false)}>
                                                                Edit
                                                            </Btn>
                                                        )}
                                                </div> */}
                                        {/* </Col>
                                        </Row> */}
                                        <Row>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="name">Name</Label>
                                                    <input className="form-control" type="text" name="name" id="name" placeholder="Name" {...register('name', { required: true })} readOnly={!editState} />
                                                    <span style={{ color: 'red' }}>{errors.name && 'Name is required'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label>Picture</Label>
                                                    <input className="form-control-file" type="file" name="profilePic" id="profilePic" accept="image/*" placeholder="Gender" {...register("profilePic", { required: false })} />
                                                    <span style={{ color: 'red' }}>{errors.profilePic && 'profile picture is required'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="email">Email</Label>
                                                    <input className="form-control" type="email" name="email" id="email" placeholder="Email" {...register("email", { required: true })} readOnly={!editState} />
                                                    <span style={{ color: 'red' }}>{errors.email && 'Email is required'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={2}>
                                                <FormGroup>
                                                    <Label>Country Code</Label>
                                                    <input className="form-control" type="text" name="countryCode" placeholder="countryCode" {...register('countryCode', { required: true })} readOnly={!editState} />
                                                    <span style={{ color: 'red' }}>{errors.countryCode && 'country code is required'}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label for="mobile">Mobile</Label>
                                                    <input className="form-control" type="text" inputMode="number" name="mobile" id="mobile" placeholder="Mobile" {...register("mobile", { required: true })} readOnly={!editState} />
                                                    <span style={{ color: 'red' }}>{errors.mobile && 'Mobile is required'}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label>Emergency Contact</Label>
                                                    <input className="form-control" type="text" inputMode="number" name="emergencyContact" id="emergencyContact" placeholder="emergencyContact" {...register("emergencyContact", { required: true })} readOnly={!editState} />
                                                    <span style={{ color: 'red' }}>{errors.emergencyContact && 'emergency contact is required'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="gender">Gender</Label>
                                                    <input className="form-control" type="text" name="gender" id="gender" placeholder="Gender" {...register("gender", { required: true })} readOnly={!editState} />
                                                    <span style={{ color: 'red' }}>{errors.gender && 'Gender is required'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label>Date of birth</Label>
                                                    <input className="form-control" type="text" name="dob" placeholder="DDMMYYYY"
                                                        {...register('dob', { required: true, pattern: /^(0[1-9]|[12][0-9]|3[01])(0[1-9]|1[0-2])\d{4}$/ })} readOnly={!editState} />
                                                    <span style={{ color: 'red' }}>{errors.dob && 'Date of birth is required and should be in DDMMYYYY format'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Row>
                                    {/* {editState &&
                                        <Row>
                                            <Col>
                                                <div className="text-end">
                                                    <Btn attrBtn={{ color: 'primary', className: 'me-3' }} >
                                                        Update
                                                    </Btn>
                                                </div >
                                            </Col>
                                        </Row>} */}
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {isModalOpen && <LogoutModal />}
            </Container>
        </Fragment >
    );
}

export default ManageProfile;