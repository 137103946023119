import { Fragment } from "react";
import { Row, Card, CardBody, CardHeader, Col, Container } from "reactstrap";
import CouponsTable from "./DataTableComponent";

const ManageCoupons = () => {
    return (
        <Fragment>
            <Container fluid={true} style={{ padding: '25px', minHeight: '150px' }}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody style={{ minHeight: '600px' }}>
                                <CouponsTable />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default ManageCoupons;