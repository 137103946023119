import { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { PlusCircle, MoreVertical, Edit, Trash2 } from "react-feather";
import { Button, Col, Row } from "reactstrap";
import { useNavigate } from "react-router-dom"
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import axios from "axios";
import { baseURL } from "../../../Services/baseURL";
import endPoints from "../../../Services/EndPoints";
import { useDataContext } from "../../../Context/hooks/useDataContext";
import * as Storage from '../../../store/LocalStorage'
import { partnerColumns } from "../../DataTableCols";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { is } from "date-fns/locale";
import LogoutModal from "../reuseModal";


const PartnersTable = () => {

    const navigate = useNavigate()
    const { partnerData, setPartnerData, isModalOpen, toggleModal } = useDataContext()
    const [loading, setLoading] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [currentpage, setCurrentPage] = useState(1);

    const token = JSON.parse(Storage.get("token"));

    const fetchAllPartners = async () => {
        try {
            setLoading(true);
            let params = {
            }
            const response = await axios.get(`${baseURL}${endPoints.getPartners}`,
                {
                    headers: {
                        Authorization: `${token}`
                    },
                    params: params
                })
            if (response?.status === 200 && response) {
                setPartnerData(response?.data?.data)
                setTotalRows(response.data.totalRecord)
                setLoading(false)
            }
        }
        catch (error) {
            console.error(error)
            if (error.response.status === 403) {
                toggleModal(true)
            }
        }
    }

    useEffect(() => {
        fetchAllPartners();
    }, [currentpage, perPage]);

    const handlePageChange = (currentPage) => {
        setCurrentPage(currentPage);
    }

    const handleRowsPerPageChange = (perPage, currentPage) => {
        setPerPage(perPage);
        setCurrentPage(currentPage);
    }

    const handleAddBtn = () => {
        navigate("/addPartner")
    }

    const handleEdit = (id) => {
        // navigate(`/partner/edit/${id}`)
    }

    const handleDelete = async (id) => {
        const confirmDelete = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this action!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        })

        if (confirmDelete.isConfirmed) {
            try {
                await axios.delete(`${baseURL}${endPoints.deletePartner}${id}`, {
                    headers: {
                        Authorization: `${token}`,
                    }
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Deleted!',
                    text: 'Partner profile has been deleted.',
                    showConfirmButton: false,
                    timer: 1500
                })
                setPartnerData(partnerData.filter(partner => partner._id !== id))
            }
            catch (error) {
                console.error("Error deleting partner:", error)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: "Something went wrong",
                    showConfirmButton: false,
                    timer: 1500
                });
            }
        }
    }

    const actionColumn = [
        {
            name: 'Action',
            selector: row => row.action,
            cell: (row) => (
                <div className="d-flex">
                    <div className="cursor-pointer">
                        <UncontrolledDropdown className='action_dropdown'>
                            <DropdownToggle className='action_btn'
                            >
                                <MoreVertical color='#000' size={16} />
                            </DropdownToggle>
                            <DropdownMenu style={{ backgroundColor: "#7366ff" }}>
                                {/* <DropdownItem className='d-flex align-items-center justify-between block px-4 py-2 text-sm text-black-700 hover:bg-gray-100 w-full' onClick={() => handleEdit(row?._id)}>
                                    Edit
                                    <Edit className="w-5 h-5 " />
                                </DropdownItem> */}
                                <DropdownItem className='d-flex align-items-center justify-between delete_item block px-4 py-2 text-sm text-black-700 hover:bg-gray-100 w-full' onClick={() => handleDelete(row?._id)}>
                                    Delete
                                    <Trash2 className="w-5 h-5 " />
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </div>
            ),
            center: true,
            sortable: false,
        }
    ]

    const allColumns = partnerColumns.concat(actionColumn)

    return (
        <Fragment>
            <Row xxl={12} className="pb-2">
                <Row>
                    <Col md={12}>
                        <div className="flex justify-between">
                            <h2>Manage Partners</h2>

                            {/* Storage.get("role_name") === "admin" ?
                                    // console.log(Storage.get("role_name")) */}
                            <Button className='btn btn-secondary-gradien h-12 w-auto d-flex flex-end align-items-center ms-3'
                                onClick={handleAddBtn} >
                                <PlusCircle className="w-6 h-6 mr-2" />
                                Add New Partner
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Row>
            {partnerData && partnerData.length !== 0 ? (
                <DataTable
                    columns={allColumns}
                    data={partnerData}
                    center={true}
                    highlightOnHover
                    striped
                    responsive
                    dense
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                />
            ) : (
                <Fragment>
                    <Row style={{ minHeight: "250px" }}>
                        <Col md={12} className="flex justify-center">
                            <div className="text-center p-5">
                                <h1 className="text-muted">No Partners Available</h1>
                            </div>
                        </Col>
                    </Row>
                </Fragment>
            )}
            {
                isModalOpen && <LogoutModal />
            }
        </Fragment>
    );
}

export default PartnersTable;