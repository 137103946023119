import React, { Fragment } from "react";
import { Col, Container, Row } from "reactstrap";
import { Breadcrumbs } from "../../../AbstractElements";

import OverallBalance from "./OverallBalance";
import GreetingCard from "./GreetingCard";
import WidgetsWrapper from "./WidgetsWraper";
import RecentOrders from "./RecentOrders";
import ActivityCard from "./ActivityCard";
import RecentSales from "./RecentSales";
import TimelineCard from "./TimelineCard";
import PreAccountCard from "./PreAccountCard";
import TotalUserAndFollower from "./TotalUserAndFollower";
import PaperNote from "./PaperNote";
// import MonthlyProfits from "../Ecommerce/MonthlyProfits";
import { SmallWidgetsData } from "../../../Data/Ecommerce";
import SmallWidgets from "../../Common/CommonWidgets/SmallWidgets";
import WidgetsGrid from "../Ecommerce/WidgetsGrid";
import ValuableCustomer from "../Ecommerce/ValuableCustomer";

const Dashboard = () => {

  return (
    <Fragment>
      <Container fluid={true} style={{ paddingTop: '30px' }}>
        <Row>
          <Col>
            <h1 className="text-center">Dashboard</h1>
            <hr />
            <p className="text-center">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );


  // return (
  //   <Fragment>
  //     <Breadcrumbs mainTitle="Default" parent="Dashboard" title="Default" />
  //     <Container fluid={true}>
  //       <Row className="row">
  //         <WidgetsGrid/>
  //         <Col xxl='3' md='5' sm='6' className='box-col-5'>
  //         <ValuableCustomer />
  //       </Col>
  //         {/* <GreetingCard /> */}
  //         {/* <WidgetsWrapper />
  //         <OverallBalance /> */}
  //         <RecentOrders />
  //         <ActivityCard />
  //         <RecentSales />
  //         <TimelineCard />
  //         <PreAccountCard />
  //         <TotalUserAndFollower />
  //         <PaperNote />
  //       </Row>
  //     </Container>
  //   </Fragment>
  // );
};

export default Dashboard;
