import { Fragment } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

const DriverOnboarding = () => {
    return ( 
        <Fragment>
            <Container>
                <Row>
                    <Col md="12">
                        <Card>
                            <CardBody>
                                <h4 className="card-title">Driver Onboarding</h4>
                                <p className="card-title-desc">
                                    Driver Onboarding is a platform that allows you to create and manage your own drivers.
                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
     );
}
 
export default DriverOnboarding;