import { Fragment, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H4 } from "../../../../AbstractElements"
import { useNavigate, useParams } from "react-router"
import axios from "axios";
import { baseURL } from "../../../../Services/baseURL";
import Swal from "sweetalert2";
// import Select from "react-select/dist/declarations/src/Select";
import { useDataContext } from "../../../../Context/hooks/useDataContext";
import endPoints from "../../../../Services/EndPoints";




const EditVehicles = () => {
    const { id } = useParams()
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm()

    const { driversData, servicesData, setServicesData } = useDataContext()
    // = useState(null)
    // console.log(driversData)
    const [selectedDriver, setSelectedDriver] = useState(null)
    const [isListVisible, setIsListVisible] = useState(false);
    const navigate = useNavigate();
    const [formData, setFormData] = useState(null)
    const token = JSON.parse(localStorage.getItem('token'))

    const fetchVehicleById = async () => {
        try {
            const URL = `${baseURL}/admin/getVehicleById/${id}`
            const response = await axios.get(URL, {
                headers: {
                    'Authorization': `${token}`
                }
            })
            // console.log(response)
            if (response?.status === 200) {
                setFormData(response?.data?.data)
            }
        }
        catch (error) {
            console.error(error)
        }
    }

    const fetchServicesData = async () => {

        const response = await axios.get(`${baseURL}${endPoints.getServices}?skip=0&limit=1000`, {
            headers: {
                Authorization: `${token}`,
            }
        })
        // console.log(response, "response")
        if (response?.status === 200) {
            // console.log(response)
            // const data = response.data.data
            // console.log(data, "response")

            // const serivceName = data.name
            // const serviceId = data._id

            // setServicesData(serivceName, serviceId)
            // // setServicesData({
            // //     value: data,
            // //     label: data.map((item) => item.name)
            // // })
            setServicesData(response.data.data)
        }
    }


    useEffect(() => {
        if (id) {
            // const { brand, cabModel, regNumber, acRide, serviceType, driverId, regImage, insurance } = formData

            if (formData && setValue) {
                const { brand, cabModel, regNumber, acRide, serviceType, driverId, regImage, insuranceNumber } = formData

                setValue("regNum", regNumber)
                setValue("acRide", acRide)
                setValue("serviceType", serviceType)
                setValue("brand", brand)
                setValue("model", cabModel)
                setValue("insuranceNumber", insuranceNumber)


                //     Object.keys(formData).forEach(key => {
                //         if (key in formData) {
                //             console.log(key, formData[key]);
                //             setValue(key, formData[key])
                //         }
                //     });
                // setValue('id', formData.id)
                // setValue('name', formData.name)
                // setValue('model', formData.model)
                // setValue('year', formData.year)
                // setValue('color', formData.color)
                // setValue('price', formData.price)
            }
        }
    }, [formData, setValue])

    useEffect(() => {
        if (id) {
            fetchVehicleById();
        }
        // if (!servicesData)
        fetchServicesData()
    }, [])

    const onSubmit = async (data) => {
        // console.log(data);
        const requestObj = {
            brand: data.brand,
            cabModel: data.model,
            regNumber: data.regNum,
            acRide: data.acRide,
            serviceType: data.serviceType,
            insuranceNumber: data.insuranceNumber
            // driverId: driverId,
        }
        // console.log(requestObj)
        try {
            if (id) {
                const URL = `${baseURL}/admin/updateVehicle/${id}`
                const response = await axios.post(URL, requestObj, {
                    headers: {
                        'Authorization': `${token}`
                    }
                })
                // console.log(response)
                Swal.fire({
                    icon: 'success',
                    title: 'Vehicle data has been updated',
                    showConfirmButton: true,
                }).then(() => {
                    navigate(-1)
                });
            }
            else {
                const URL = `${baseURL}/admin/addVehicle`
                const response = await axios.post(URL, requestObj, {
                    headers: {
                        'Authorization': `${token}`
                    }
                })
                // console.log(response)
                Swal.fire({
                    icon: 'success',
                    title: 'Vehicle Added successfully',
                    showConfirmButton: false,
                    timer: 1500
                }).then(() => {
                    navigate(-1)
                });
            }
        }
        catch (error) {
            console.error(error)
            Swal.fire({
                icon: 'error',
                title: error.response.data.responseMessage,
                showConfirmButton: true,
            });
        }
    }

    // const handleDriverInputChange = (driver) => {
    //     setSelectedDriver(driver)
    //     setIsListVisible(!!input);
    // }

    // const handleDriverInputFocus = () => {
    //     setIsListVisible(true);
    // }

    return (
        <Fragment>
            <Container fluid={true}>
                <div className="page-title">
                    <Row>
                        <Col xs='6'>
                            <H4>{id ? "Edit Vehicle" : "Add New Vehicle"}</H4>
                        </Col>
                        <Col xs="6">
                            <div className="text-right">
                                <Btn attrBtn={{ className: "btn-secondary-gradien", type: "button" }} navigateBack={true}>Back</Btn>
                            </div >
                        </Col >
                    </Row >
                </div>
            </Container>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <Col sm="12">
                                            <FormGroup>
                                                <Label>Vehicle Brand</Label>
                                                <input className="form-control" {...register("brand", { required: true })} type="text" id="brand" name="brand" placeholder="Enter Brand Name" />
                                                <span style={{ color: 'red' }}>{errors.brand && 'Brand name is required'}</span>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="12">
                                            <FormGroup>
                                                <Label>Model</Label>
                                                <input className="form-control" {...register("model", { required: true })} type="text" id="model" name="model" placeholder="Enter Vehicle Model Name" />
                                                <span style={{ color: 'red' }}>{errors.model && 'Model name is required'}</span>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="12">
                                            <FormGroup>
                                                <Label>Registration Number</Label>
                                                <input className="form-control" {...register("regNum", { required: true })} type="text" id="regNum" name="regNum" placeholder="Enter Vehicle Reg. No." />
                                                <span style={{ color: 'red' }}>{errors.regNum && 'Registration No. is required'}</span>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="12">
                                            <FormGroup>
                                                <Label>Air Condition</Label>
                                                {/* <Input type="select"
                                        onChange={(e) => {
                                            // const selectedDriverVal = (e.target.value);
                                            setSelectedDriver(e.target.value)
                                        }}
                                        className="form-control"
                                        value={selectedDriver}
                                    >
                                        <option value="">Select Driver</option>
                                        {
                                            driversData.map(driver => (
                                                <option key={driver._id} value={driver._id} > {driver.name}</option>
                                            ))
                                        }
                                    </Input> */}
                                                <select
                                                    className="form-control"
                                                    {...register("acRide", { required: true })}
                                                    type="select"
                                                    id="acRide"
                                                    name="acRide"
                                                    placeholder="Select true or false"
                                                >
                                                    <option value="">Select</option>
                                                    <option value="true">True</option>
                                                    <option value="false">False</option>
                                                </select>
                                                <span style={{ color: 'red' }}>{errors.acRide && 'Select AC true or false'}</span>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="12">
                                            <FormGroup>
                                                <Label for="serviceType">Service Type</Label>
                                                <select
                                                    className="form-control"
                                                    {...register("serviceType", { required: true })}
                                                    type="select"
                                                    id="serviceType"
                                                    name="serviceType"
                                                    placeholder="Select service type"
                                                    style={{ fontSize: '16px', padding: '8px', lineHeight: '1.5' }}
                                                >
                                                    <option value="" >Select service type</option>
                                                    {
                                                        servicesData.map(service => (
                                                            <option key={service._id} value={service._id}>{service.name}</option>
                                                        ))
                                                    }
                                                </select>
                                                <span style={{ color: 'red' }}>{errors.serviceType && 'service type is required'}</span>
                                            </FormGroup>
                                        </Col>
                                        {/* <Col sm="12">
                                            <FormGroup>
                                                <Label for="serviceType">Service Type</Label>
                                                <input className="form-control" {...register("serviceType", { required: true })} type="text" id="serviceType" name="serviceType" placeholder="Enter service type" />
                                                <span style={{ color: 'red' }}>{errors.serviceType && 'service type is required'}</span>
                                            </FormGroup>
                                        </Col> */}
                                        <Col sm="12">
                                            <FormGroup>
                                                <Label for="insuranceNumber">Insurance Number</Label>
                                                <input className="form-control" {...register("insuranceNumber", { required: false })} type="text" id="insuranceNumber" name="insuranceNumber" placeholder="Enter Insurance No." />
                                                <span style={{ color: 'red' }}>{errors.insuranceNumber && 'Insurance No is required'}</span>
                                            </FormGroup>
                                        </Col>
                                        {/* <Col sm="12">
                                            <FormGroup>
                                                <Label>Enroll Driver</Label>
                                                <Input type="select" name="driver" className="form-control">
                                                    <option>
                                                        {driversData ? (
                                                            driversData.map((driver) => {
                                                                // return (
                                                                    <option key={driver?.id} value={driver?.id}>{driver?.name}</option>
                                                                // )
                                                            })
                                                        ) : null}
                                                    </option>
                                                </Input>
                                            </FormGroup>
                                        </Col> */}
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="text-end" style={{ marginRight: "30px", marginTop: "25px" }}>
                                                <Btn attrBtn={{ className: 'me-3 btn-secondary-gradien', type: "submit" }}>
                                                    {id ? "Update vehicle" : "Add vehicle"}
                                                </Btn>
                                                {/* {!id ?  */}
                                                <Button className="btn-secondary-gradien" type="button" onClick={() => reset()}>
                                                    Reset
                                                </Button>
                                                {/* // : ""} */}
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container >
        </Fragment >
    )
}


export default EditVehicles